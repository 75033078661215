import LinkWrapper from "@/components/wrappers/Link/Link";
import { ASSETS_URL, BASE_URL } from "@/config/deployConstants";
import { AccountType, UserDetails } from "@knowt/syncing/graphql/schema";
import { PricingOption } from "@knowt/syncing/hooks/user/subscriptionConstants";
import { TIME_SECONDS } from "@knowt/syncing/utils/dateTimeUtils";
import { numberWithCommas } from "@knowt/syncing/utils/stringUtils";
import { ReactNode } from "react";
import {
    STUDENTS_ANNUALLY_LIMITLESS,
    STUDENTS_ANNUALLY_SUPPORTER,
    STUDENTS_MONTHLY_BASIC,
    STUDENTS_MONTHLY_LIMITLESS,
    STUDENTS_MONTHLY_SUPPORTER,
    STUDENT_ANNUALLY_LIMITLESS_BULK_50,
    STUDENT_ANNUALLY_LIMITLESS_BULK_150,
    STUDENT_ANNUALLY_LIMITLESS_BULK_500,
    STUDENT_ANNUALLY_LIMITLESS_BULK_1000,
    STUDENT_ANNUALLY_LIMITLESS_BULK_2000,
    STUDENT_ANNUALLY_LIMITLESS_PICKER,
    STUDENT_ANNUALLY_PLUS_PICKER,
    STUDENT_MONTHLY_BASIC_PICKER,
} from "./studentsPlans";
import {
    ENTREPRISE_CONTACT_US,
    TEACHERS_ANNUALLY_LIMITLESS,
    TEACHERS_ANNUALLY_LIMITLESS_PICKER,
    TEACHERS_ANNUALLY_PLUS_PICKER,
    TEACHERS_ANNUALLY_PRO,
    TEACHERS_MONTHLY_BASIC,
    TEACHERS_MONTHLY_BASIC_PICKER,
    TEACHERS_MONTHLY_LIMITLESS,
    TEACHERS_MONTHLY_PRO,
} from "./teachersPlans";

export type RefundType = "FULL" | "PARTIAL" | "NONE" | "TRIAL" | "FAILED";

export enum OfferTypes {
    UNIDAYS = "UNiDAYS",
    STUDENT_BEANS = "StudentBeans",
    INITIAL_SETUP = "InitialSetup",
}

export const REFUNDS: {
    [key in RefundType]: RefundType;
} = {
    FULL: "FULL",
    PARTIAL: "PARTIAL",
    NONE: "NONE",
    TRIAL: "TRIAL",
    FAILED: "FAILED",
};

export const REFUND_TIME_LIMITS = {
    FULL: 2 * TIME_SECONDS.DAY, // canceling before 48 hours after auto reniewal
    PARTIAL: 3 * TIME_SECONDS.DAY, // canceling between 48 and 72 hours after auto reniewal
};

export const REFUND_OPTIONS = {
    [REFUNDS.TRIAL]: {
        primary: "keep subscription with 50% off",
        secondary: "Cancel subscription",
        primaryButton: "Continue with 50% off",
        offer: "COUPON",
    },
    [REFUNDS.FULL]: {
        primary: "Keep my plan with 50% a refund",
        secondary: "I want a full refund",
        primaryButton: "Continue with 50% refund",
        offer: "REFUND",
    },
    [REFUNDS.PARTIAL]: {
        primary: "Keep my plan with 50% a refund",
        secondary: "I want a partial refund",
        primaryButton: "Continue with 50% refund",
        offer: "REFUND",
    },
    [REFUNDS.NONE]: {
        primary: "Keep auto-renewal on",
        secondary: "Turn off auto-renewal",
    },
    [REFUNDS.FAILED]: {
        primary: "Keep auto-renewal on",
        secondary: "Turn off auto-renewal",
    },
};

export const CANCEL_SUCCESS_REFUND_OPTIONS = {
    [REFUNDS.FULL]: {
        title: "Your subscription has been cancelled, and your refund will be processed in 5-10 business days.",
        description: () => (
            <p>
                We’re sad to see you cancelled your plan but you’ll still be able to use the free features in Knowt!
                You’ll see the balance return to your bank account in 5-10 business days -- but if you don’t please fill
                out the form on knowt.com/contact.
            </p>
        ),
    },
    [REFUNDS.PARTIAL]: {
        title: "Your subscription has been cancelled, and your refund will be processed in 5-10 business days.",
        description: () => (
            <p>
                We’re sad to see you cancelled your plan but you’ll still be able to use the free features in Knowt!
                You’ll see the balance return to your bank account in 5-10 business days -- but if you don’t please fill
                out the form on knowt.com/contact.
            </p>
        ),
    },
    [REFUNDS.NONE]: {
        title: "We’ve turned off auto-renewal for your subscription.",
        description: (date?: string) => (
            <p>
                We’re sad to see you want to cancel your plan but you’ll still be able to access the features on the
                plan until the plan ends on <span className="bodyBold1">{date}</span>! You weren’t eligible for a
                refund, but we’ve turned off your auto-renewal so you won’t get charged when your subscription ends on
                <span className="bodyBold1">{date}</span>
            </p>
        ),
    },
    [REFUNDS.TRIAL]: {
        title: "We've cancelled your trial, and you won't be charged.",
        description: () => (
            <p>
                We’re sad to see you cancelled your trial but you’ll still be able to use the free features in Knowt!
                You won’t be charged for the trial, but if you have any questions please fill out the form on
                knowt.com/contact.
            </p>
        ),
    },
    [REFUNDS.FAILED]: {
        title: "Your last payment failed. There's nothing to refund. We've turned off auto-renewal for your subscription.",
        description: () => (
            <p>
                We’re sad to see you cancelled your plan. but you’ll still be able to use the free features in Knowt!
                You won’t be charged for any future payments, but if you have any questions please fill out the form on
                knowt.com/contact.
            </p>
        ),
    },
};

export type PricingPropsUI = PricingOption & {
    description?: string;
    features: string[];
    onClick?: () => void;
    icon?: string;
    background?: string;
    buttonBackground?: string;
    promo?: number;
    mostPopular?: boolean;
    benefits?: string[];
    isPicker?: boolean;
    needContactUs?: boolean;
    nbrLicenses?: number;
    intervalText?: string;
    specialBenefit?: {
        benefit: string;
        icon: ReactNode;
        style: React.CSSProperties;
    };
    upTo?: number;
    perfectFor?: string;
    minimumPurchase?: string;
    isCustom?: boolean;
};

// TODO: pricingProps to `pricingCard` as props instead
export type PricingCardProps = PricingPropsUI & {
    buttonText?: string;
    renderTopBadge?: React.ReactNode;
    isCurrentPlan?: boolean;
};

export const STUDENTS_PRICING_OPTIONS_UI = [
    STUDENTS_MONTHLY_SUPPORTER,
    STUDENTS_ANNUALLY_SUPPORTER,
    STUDENTS_MONTHLY_LIMITLESS,
    STUDENTS_ANNUALLY_LIMITLESS,
];

// TODO: change teacher pricing options
export const TEACHERS_PRICING_OPTIONS_UI = [
    TEACHERS_MONTHLY_PRO,
    TEACHERS_ANNUALLY_PRO,
    TEACHERS_MONTHLY_LIMITLESS,
    TEACHERS_ANNUALLY_LIMITLESS,
];

export const STUDENTS_PRICING_COMPARISON_UI = [
    STUDENTS_MONTHLY_BASIC,
    STUDENTS_MONTHLY_SUPPORTER,
    STUDENTS_ANNUALLY_SUPPORTER,
    STUDENTS_MONTHLY_LIMITLESS,
    STUDENTS_ANNUALLY_LIMITLESS,
];

export const TEACHERS_PRICING_COMPARISON_UI = [
    TEACHERS_MONTHLY_BASIC,
    TEACHERS_MONTHLY_PRO,
    TEACHERS_ANNUALLY_PRO,
    TEACHERS_MONTHLY_LIMITLESS,
    TEACHERS_ANNUALLY_LIMITLESS,
];

export const TEACHERS_PRICING_PICKER_UI = (nbrLicense: number) => [
    TEACHERS_ANNUALLY_PLUS_PICKER(nbrLicense),
    TEACHERS_ANNUALLY_LIMITLESS_PICKER(nbrLicense),
    ENTREPRISE_CONTACT_US,
];

export const STUDENTS_PRICING_PICKER_UI = (nbrLicense: number) => [
    STUDENT_MONTHLY_BASIC_PICKER,
    STUDENT_ANNUALLY_PLUS_PICKER(nbrLicense),
    ENTREPRISE_CONTACT_US,
];

export const BULK_PRICING_UI = () => [
    STUDENT_ANNUALLY_LIMITLESS_BULK_50(),
    STUDENT_ANNUALLY_LIMITLESS_BULK_150(),
    STUDENT_ANNUALLY_LIMITLESS_BULK_500(),
    STUDENT_ANNUALLY_LIMITLESS_BULK_1000(),
    STUDENT_ANNUALLY_LIMITLESS_BULK_2000(),
];

export const STUDENTS_PRICING_OPTIONS_DETAILS = {
    MONTHLY_BASIC: STUDENTS_MONTHLY_BASIC,
    MONTHLY_SUPPORTER: STUDENTS_MONTHLY_SUPPORTER,
    ANNUALLY_SUPPORTER: STUDENTS_ANNUALLY_SUPPORTER,
    MONTHLY_LIMITLESS: STUDENTS_MONTHLY_LIMITLESS,
    ANNUALLY_LIMITLESS: STUDENTS_ANNUALLY_LIMITLESS,
};

export const TEACHERS_PRICING_OPTIONS_DETAILS = {
    MONTHLY_BASIC: TEACHERS_MONTHLY_BASIC,
    MONTHLY_TEACHER_PRO: TEACHERS_MONTHLY_PRO,
    ANNUALLY_TEACHER_PRO: TEACHERS_ANNUALLY_PRO,
    MONTHLY_LIMITLESS: TEACHERS_MONTHLY_LIMITLESS,
    ANNUALLY_LIMITLESS: TEACHERS_ANNUALLY_LIMITLESS,
};

export const DISTRICT_TEACHERS_PRICING_PICKER_UI = (nbrLicense: number) => [
    TEACHERS_MONTHLY_BASIC_PICKER,
    TEACHERS_ANNUALLY_PLUS_PICKER(nbrLicense),
    TEACHERS_ANNUALLY_LIMITLESS_PICKER(nbrLicense),
];

export const DISTRICT_STUDENTS_PRICING_PICKER_UI = (nbrLicense: number) => [
    STUDENT_MONTHLY_BASIC_PICKER,
    STUDENT_ANNUALLY_PLUS_PICKER(nbrLicense),
    STUDENT_ANNUALLY_LIMITLESS_PICKER(nbrLicense),
];

const MAX_DISCOUNT = 0.3;

/*
10 to 39 Licenses	5%
40 to 99 Licenses	10%
100 to 199 Licenses	50%
200 to 399 Licenses	20%
400 to 799 Licenses	25%
799 License+	30%
*/
export const BULK_DISCOUNTS: { licenses: number; discount: number }[] = [
    { licenses: 50, discount: 0 },
    { licenses: 150, discount: 1 - 39.99 / 59.99 },
    { licenses: 500, discount: 1 - 19.99 / 59.99 },
    { licenses: 1000, discount: 1 - 13.99 / 59.99 },
    { licenses: 2000, discount: 1 - 7.99 / 59.99 },
    { licenses: 3000, discount: 1 - 5.99 / 59.99 },
];

export const customPricingCard = {
    icon: `${ASSETS_URL}/images/district-icon.svg`,
    type: "District",
    subtitle: "Plans for your whole school district",
    intervalPrice: "Custom",
    intervalText: "sold by school year",
    buttonText: "contact us for pricing",
    description: "Your perks",
    features: [
        "Discount pricing for bulk teacher and student plans",
        "No ads for all your users",
        "Admin portal to manage all accounts",
        "Links with Clever, Edlink, and others for SSO",
    ],
    oldPrice: null,
    buttonBackgroundColor: null,
    onClick: () => window.open("https://form.fillout.com/t/7ZQh3S6aWQus", "_blank"),
    isSimpleClick: true,
    price: "Custom",
};

export const formatPrice = (price: number) => {
    // add commas to the price
    return `$${numberWithCommas(price.toFixed(2))}`;
};

export const getBulkDiscount = (nbrLicenses: number) => {
    return BULK_DISCOUNTS.find(({ licenses }) => nbrLicenses <= licenses)?.discount ?? MAX_DISCOUNT;
};

export const getQuotedPrice = ({
    student: { plan: studentPlan, count: studentCount },
    teacher: { plan: teacherPlan, count: teacherCount },
}: {
    student: { plan: PricingPropsUI; count: number };
    teacher: { plan: PricingPropsUI; count: number };
}) => {
    const oldPrice =
        Number(teacherPlan.oldPrice?.slice(1) || 0) * teacherCount +
        Number(studentPlan.oldPrice?.slice(1) || 0) * studentCount;

    const discount = getBulkDiscount(teacherCount + studentCount);

    return {
        oldPrice: formatPrice(oldPrice),
        discount,
        newPrice: formatPrice((1 - discount) * oldPrice),
    };
};

export const getBulkDiscountPrice = (nbrLicenses: number, originalPrice: string) => {
    const discount = getBulkDiscount(nbrLicenses);

    return formatPrice((1 - discount) * Number(originalPrice.slice(1)));
};

// TODO (refactor): probably move to syncing?
export const getPricingOptionUI = (user?: UserDetails | null): PricingPropsUI => {
    const isTeacher = user?.accountType === AccountType.Teacher;

    const noSubscriptionPlan = (isTeacher ? TEACHERS_PRICING_OPTIONS_DETAILS : STUDENTS_PRICING_OPTIONS_DETAILS)
        .MONTHLY_BASIC;

    if (!user || !user.subscriptions?.[0]) {
        return noSubscriptionPlan;
    }

    return (
        (isTeacher ? TEACHERS_PRICING_COMPARISON_UI : STUDENTS_PRICING_OPTIONS_UI).find(
            ({ subscriptionType, interval }) => {
                return (
                    subscriptionType === user.subscriptionType &&
                    interval.toLowerCase() === user.subscriptions?.[0].interval?.toLowerCase()
                );
            }
        ) ?? noSubscriptionPlan
    );
};

export const getPricingOptionByPriceId = (priceId: string): PricingPropsUI => {
    if (!priceId) {
        return STUDENTS_MONTHLY_BASIC;
    }

    const plans = [
        ...Object.values(TEACHERS_PRICING_OPTIONS_DETAILS),
        ...Object.values(STUDENTS_PRICING_OPTIONS_DETAILS),
    ];

    return plans.find(plan => plan.priceId === priceId) ?? STUDENTS_MONTHLY_BASIC;
};

export const faqs = [
    {
        question: "Is there a free trial for the Knowt plans?",
        answer: "Yes! We start you off with some complimentary uses of all our AI features so you can test it out before you choose a plan. Once you've used up your complimentary features or if you want to try one of our exclusive premium features, you can sign up for our Plus or Ultra plans!",
    },
    {
        question: "What's included in the Free plan?",
        answer: [
            "You have our guarantee that the core features on Knowt will ALWAYS be free! You can create notes & flashcard sets, study flashcards with unlimited rounds of learn mode or any of our study modes and use our dual-screen video & PDF editors completely free.",
            "However, if you're looking to upgrade your learning with AI features we've uniquely built to help you save time and energy when studying, we highly recommend trying out a free trial for one of our premium plans.",
        ],
    },
    {
        question: "How can I get a discount on the Knowt premium plans? ",
        answer: "You get a 25% discount off our Ultra plan & 40% discount off our Pro plan when you choose the annual billing option!",
    },
    {
        question: "Can I purchase one of these plans for my organization at a cheaper price?",
        answer: (
            <span>
                If you like to purchase ad-free Knowt or one of our premium plans for your organization, we offer
                discounted pricing! Please fill out this{" "}
                <LinkWrapper className="knowtLink" openInNewTab href={"/schools/bulk-pricing"}>
                    form
                </LinkWrapper>{" "}
                and our CEO will get in touch with you shortly.
            </span>
        ),
    },
    {
        question: "How do refunds work?",
        answer: [
            "Our refund policy is simple. If you believe you mistakenly signed up or renewed a paid Knowt subscription, contact us at knowt.com/contact within 48 hours of renewal or initial subscription and we’ll refund you the full amount (minus the ~3.5% transaction fees incurred by Knowt for processing the refund). You can reach out up to 72 hours after in order to receive a 50% refund as well (minus the ~3.5% transaction fees incurred by Knowt for processing the refund).",
            "If for some reason, you’re unhappy with your Knowt subscription, please email us at knowt.com/contact and let us know. We want to make your experience studying on Knowt the best it can be, so we are always open to hearing your feedback.",
        ],
    },
    {
        question: "How do I change my payment method?",
        answer: "You can change your payment method at any time by going to your Settings page, scrolling down to the Plans section and clicking Manage Billing.",
    },
    {
        question: "What happens when I change plans?",
        answer: [
            "When you upgrade your plan, your account will be upgraded and charge the amount due immediately. The amount of money you pay will be automatically reduced based on the percentage of the billing cycle left at the time the plan was changed.",
            "When you downgrade a plan, your account will be downgraded at the end of your billing cycle.",
        ],
    },
    {
        question: "How do I cancel my subscription?",
        answer: "Your Knowt subscription, annually and monthly, automatically renews until you cancel it. You can cancel your subscription at any time by going to your Settings page, scrolling down to the Plans section and clicking Manage Billing. Once you cancel your subscription, you'll have access to all the paid features until the end of your billing cycle.",
    },
];

const baseLink = "https://help.knowt.com/en/";
export const LearnHowLinks = {
    practiceTestLink: BASE_URL + "/ai-notes",
    flashcardFromNotesLink: BASE_URL + "/ai-notes",
    brainstormingLink: baseLink + "19743583074068-How-to-use-AI-for-your-notes",
    customizeProfileLink: baseLink + "19743195305108-How-to-update-your-profile-page",
    passwordProtectLink: baseLink + "19901968764692-Password-protect-your-files",
    notesFromVideoLink: BASE_URL + "/ai-pdf-summarizer",
};
