import { ItemType, Language } from "@knowt/syncing/graphql/schema";
import { FlashcardFormatEnum, NoteFormatEnum, NoteLengthEnum } from "@knowt/syncing/hooks/ai/constants";

export type GenerateableContentTypes = ItemType.NOTE | ItemType.FLASHCARDSET;

export const AI_GENERATION_SETTINGS_LS_KEY = "ai_generation_settings_";

export type AIGenerationSettingsProps = {
    title: string;
    contentType: GenerateableContentTypes | null;
    language: Language;
    noteSummaryLength: NoteLengthEnum;
    flashcardsLength: number | undefined;
    noteSummaryType: NoteFormatEnum;
    flashcardType: FlashcardFormatEnum;
    pdfPages: number[] | null;
};
