import { platform } from "@/platform";
import { ItemType, Media, MediaType, TranscriptEnum } from "@knowt/syncing/graphql/schema";
import { useCurrentUser } from "@knowt/syncing/hooks/user/useCurrentUser";
import { useCallback } from "react";

export const useAIValidations = ({ sourceItemType }: { sourceItemType: ItemType.MEDIA | ItemType.NOTE }) => {
    const { hasReachedAIUsage } = useCurrentUser();

    const verifyAIUsage = useCallback(() => {
        if (sourceItemType === ItemType.NOTE) return !hasReachedAIUsage("nSets");

        return (
            !hasReachedAIUsage("videos") &&
            !hasReachedAIUsage("mNotes") &&
            !hasReachedAIUsage("mSets") &&
            !hasReachedAIUsage("pdfs")
        );
    }, [hasReachedAIUsage, sourceItemType]);

    const verifyMediaTranscript = async (media: Media) => {
        if (media.transcript) {
            if (media.transcript === TranscriptEnum.NONE) {
                const toast = await platform.toast();
                toast.error(
                    media.type === MediaType.VIDEO
                        ? "Uh oh! This video might not have audio, or it may be in a language Kai can't understand yet. Please visit the help center for more information on the supported languages."
                        : "Uh oh! Kai wasn't able to read the text on this document. It may be too blurry, or in a language that Kai can't understand yet."
                );
                return false;
            }
        }
        return true;
    };

    return {
        verifyAIUsage,
        verifyMediaTranscript,
    };
};
