"use client";
import { setRedirectAfterLogin } from "@/app/page/rootComponents/AuthHubListener";
import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import { UserDetails } from "@knowt/syncing/graphql/schema";
import { SocialAuthProvider, cleanEmail, federateSignIn, signIn } from "@knowt/syncing/hooks/user/auth";
import { TEMP_CACHED_SIGN_IN_SOURCE_KEY } from "@knowt/syncing/hooks/user/types";
import {
    validateAccountType,
    validateBirthdayByPlainBirthdayString,
    validateUsername,
} from "@knowt/syncing/hooks/user/userVerificationUtils";
import { updateUserInfo } from "@knowt/syncing/hooks/user/utils";
import { TIME_SECONDS, now } from "@knowt/syncing/utils/dateTimeUtils";
import { ReadonlyURLSearchParams } from "next/navigation";
import { OfferTypes } from "../Payments/utils/constants";
import { AuthEvent } from "./AuthPopup/utils/constants";

export const SS_REDIRECT_KEY = "newUserPageRedirect";
export const SS_PARAMS_KEY = "newUserPageParams";
export const SS_SIGNOUT_REDIRECT_KEY = "signoutRedirect";
export const SS_OFFERS_PROMO = (offer: OfferTypes) => `${offer}PromoCode`;
export const SS_MANUALLY_VERIFY_SIGNIN_KEY = "manuallyVerifySignin";

export const socialSignIn = async ({
    provider,
    event,
    pathname,
    searchParams,
}: {
    provider: SocialAuthProvider;
    event: AuthEvent;
    pathname: string;
    searchParams: ReadonlyURLSearchParams;
}) => {
    try {
        setRedirectAfterLogin(pathname, searchParams);
        await LocalKeyValueStore.setWithExpiry(TEMP_CACHED_SIGN_IN_SOURCE_KEY, event);
        await federateSignIn(provider);
    } catch (err) {
        if (err.name === "UserAlreadyAuthenticatedException") {
            window.location.reload();
        } else {
            throw err;
        }
    }
};

export const maybeLogReferral = async (user: UserDetails) => {
    const refFrom_v2 = localStorage.getItem("refFrom_v2");

    // if there is a referral, and the account is created within 24 hours, then track this referral
    if (refFrom_v2 && now() - Number(user.created) < TIME_SECONDS.DAY) {
        localStorage.removeItem("refFrom_v2");
        user = await updateUserInfo({ refFrom_v2 });
    }

    window.sessionStorage.removeItem(SS_PARAMS_KEY);
    return user;
};

export const validateUserAdditionalIdentity = ({ username, birthday, accountType }) => {
    validateBirthdayByPlainBirthdayString(birthday);
    validateUsername(username);
    validateAccountType(accountType);
};

export const authenticateUserCredentials = async ({ email, password }: { email: string; password: string }) => {
    const cleanedEmail = cleanEmail(email);

    return await signIn(cleanedEmail, password);
};
