"use client";

import LazyLoaded from "@/components/LazyLoaded";
import { STORAGE_KEYS } from "@/config/constants";
import { callAddMediaS3UserTag } from "@/hooks/media/graphqlUtils";
import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import { wait } from "@/utils/genericUtils";
import useCombinedState from "@/utils/hooks/useCombinedState";
import { createAndOpenNewNote } from "@/utils/navigation/notes";
import getStripe from "@/utils/stripe/stripeJS";
import { fetchPostJSON } from "@knowt/syncing/fetchFunctions/fetchWrappers";
import { ItemType, UpgradeEvent, UserDetails } from "@knowt/syncing/graphql/schema";
import { fetchServerUserInfo } from "@knowt/syncing/hooks/user/auth";
import { STUDENTS_PRICING_OPTIONS } from "@knowt/syncing/hooks/user/subscriptionConstants";
import { Hub } from "aws-amplify/utils";
import dynamic from "next/dynamic";
import { ReadonlyURLSearchParams, usePathname } from "next/navigation";
import { useRouter } from "@bprogress/next";
import { useCallback, useEffect } from "react";
import toast from "react-hot-toast";
import "aws-amplify/auth/enable-oauth-listener";
import { AIGenerationSettingsProps, AI_GENERATION_SETTINGS_LS_KEY } from "@/features/AI/AIGenerationSettings/types";
import { useAuthSlideContextSelector } from "@/features/Auth/AuthSlide/hook/useAuthSlideContext";
import {
    SS_OFFERS_PROMO,
    SS_PARAMS_KEY,
    SS_REDIRECT_KEY,
    SS_SIGNOUT_REDIRECT_KEY,
    maybeLogReferral,
} from "@/features/Auth/utils";
import { getInitialSetupUrl } from "@/features/InitialSetupPage/utils";
import { useUpgradePopupContextSelector } from "@/features/Payments/PaywallPopup/UpgradePopupContext";
import { OfferTypes } from "@/features/Payments/utils/constants";
import { dynamicKeyValueStore } from "@/utils/dynamicKeyValueStore";
import { removeCookie } from "@/utils/ssr/nextCookieServerUtils";
import { AI_TEACHER_TOOLS_SETTINGS_LS_KEY } from "@knowt/syncing/constants/ai-tools";
import { LandingPageNoteData, LandingPageQuizletImportLink, MediaUploadedData } from "@knowt/syncing/constants/storage";
import { useAIValidations } from "@knowt/syncing/hooks/ai/useAIValidations";
import { TOOL_URL } from "@knowt/syncing/utils/aiTools";
import { generalizedMediaTypeFromSingleFileType } from "@knowt/syncing/utils/fileTypeUtils";

const ImportFromQuizletPopupLandingPageFlow = dynamic(
    () => import("@/components/Popup/ImportFromQuizletPopup/ImportFromQuizletPopupLandingPageFlow")
);

const SummarizeVideoFlowPopup = dynamic(() => import("@/components/Popup/SummarizeVideoFlowPopup"));

export const redirectAfterLoginIfNeeded = () => {
    const redirectRoute = window.sessionStorage.getItem(SS_REDIRECT_KEY) ?? "/";

    if (redirectRoute === window.location.pathname) {
        window.sessionStorage.removeItem(SS_REDIRECT_KEY);
        window.location.reload();
    }

    if (redirectRoute) {
        window.location.replace(redirectRoute);
        window.sessionStorage.removeItem(SS_REDIRECT_KEY);
    } else if (window.location.pathname !== "/") {
        window.location.replace("/");
    }
};

export const setRedirectAfterLogin = (
    pathname: string,
    searchParams: ReadonlyURLSearchParams = new ReadonlyURLSearchParams(new URLSearchParams())
) => {
    if (!pathname) return;

    // if there is a custom redirect set
    if (searchParams.has("redirect")) {
        window.sessionStorage.setItem(SS_REDIRECT_KEY, decodeURIComponent(searchParams.get("redirect") as string));
        window.sessionStorage.setItem(SS_PARAMS_KEY, JSON.stringify(searchParams.entries()));
        return;
    }

    if (
        [
            "/login",
            "/signup",
            "/verify",
            "/reset",
            "/goodbye",
            "/",
            "/ai-video-summarizer",
            "/ai-pdf-summarizer",
            "/ai-powerpoint-summarizer",
            "/ai-spreadsheet-summarizer",
            "/ai-notes",
            "/teachers",
            "/schools",
            "/sso/login",
            "/sso/login/error",
        ].every(path => path !== pathname)
    ) {
        const fullPath = (pathname as string) + (searchParams ? "?" + searchParams.toString() : "");
        window.sessionStorage.setItem(SS_REDIRECT_KEY, fullPath);
        window.sessionStorage.setItem(SS_PARAMS_KEY, JSON.stringify(searchParams.entries()));
    } else {
        // if one of the landing pages
        window.sessionStorage.setItem(SS_REDIRECT_KEY, "/");
        window.sessionStorage.setItem(SS_PARAMS_KEY, JSON.stringify(searchParams.entries()));
    }
};

/**
 * This is needed for sign out with socials, as it auto redirects to the /goodbye page
 * @param redirect The URL to redirect to after sign out
 */
export const setRedirectOnSignOut = (redirect: string) => {
    window.sessionStorage.setItem(SS_SIGNOUT_REDIRECT_KEY, redirect);
};

export const AuthHubListener = () => {
    const router = useRouter();
    const pathname = usePathname();

    const { verifyAIUsage } = useAIValidations({
        sourceItemType: ItemType.MEDIA,
    });
    const openUpgradePopup = useUpgradePopupContextSelector(state => state.openUpgradePopup);

    const [importFromQuizletFlowStates, updateImportFromQuizletFlowStates] = useCombinedState<{
        quizletUrl: string | null;
        isPopupOpen: boolean;
    }>({
        quizletUrl: null,
        isPopupOpen: false,
    });

    const [importFromVideoSummarizeFlowStates, updateImportFromVideoSummarizeFlowStates] = useCombinedState<{
        videoUrl: string | null;
        isPopupOpen: boolean;
    }>({
        videoUrl: null,
        isPopupOpen: false,
    });

    const closeAuthSlide = useAuthSlideContextSelector(state => state.closeAuthSlide);

    const createUnidaysCheckoutSession = useCallback(
        async (user: UserDetails | null | undefined, offerPromoCode: string, offerType: OfferTypes) => {
            const response = await fetchPostJSON("/api/checkout_sessions", {
                priceId: STUDENTS_PRICING_OPTIONS.MONTHLY_LIMITLESS.priceId,
                email: user?.Email,
                // code param
                code: offerPromoCode,
                ...(offerType === OfferTypes.UNIDAYS && { offerType }),
            });

            if (response.statusCode === 500) {
                return;
            }

            const stripe = await getStripe();
            await stripe?.redirectToCheckout({ sessionId: response.data.id });
        },
        []
    );

    useEffect(() => {
        return Hub.listen("auth", async ({ payload: { event } }) => {
            if (event === "signedOut") {
                // clear all relevant cookies.
                removeCookie(STORAGE_KEYS.HOME_PAGE_FILES_TAB);
                removeCookie(STORAGE_KEYS.HOME_PAGE_TAB);
                removeCookie(STORAGE_KEYS.HOME_PAGE_SORT_AND_DIRECTION);
            }

            if (event !== "signedIn") return;

            try {
                // used so that the user source gets logged
                const serverUser = await fetchServerUserInfo({ forceStripeVerify: true });
                let user = serverUser.user;
                const organization = serverUser.organization;

                if (!user?.ID) return null;

                user = await maybeLogReferral(user);

                const unidaysPromo = window.sessionStorage.getItem(SS_OFFERS_PROMO(OfferTypes.UNIDAYS));
                const studentBeansPromo = window.sessionStorage.getItem(SS_OFFERS_PROMO(OfferTypes.STUDENT_BEANS));
                if (unidaysPromo || studentBeansPromo) {
                    window.sessionStorage.removeItem(SS_OFFERS_PROMO(OfferTypes.UNIDAYS));
                    window.sessionStorage.removeItem(SS_OFFERS_PROMO(OfferTypes.STUDENT_BEANS));
                    await createUnidaysCheckoutSession(
                        user,
                        (unidaysPromo || studentBeansPromo) as string,
                        unidaysPromo ? OfferTypes.UNIDAYS : OfferTypes.STUDENT_BEANS
                    );
                }

                // TODO(types): strongly type these keys: mediaUploadedData, mediaUploadedMetadata, landingPageNoteData
                const uploadedMediaData = (await LocalKeyValueStore.getWithExpiry(
                    STORAGE_KEYS.MEDIA_UPLOADED_DATA
                )) as MediaUploadedData;

                const aiGenerationSettings = JSON.parse(
                    (await dynamicKeyValueStore.get(AI_GENERATION_SETTINGS_LS_KEY + uploadedMediaData?.mediaId)) || "{}"
                ) as AIGenerationSettingsProps;

                const isFileVideo = generalizedMediaTypeFromSingleFileType(uploadedMediaData?.extension || "");

                await LocalKeyValueStore.remove(STORAGE_KEYS.MEDIA_UPLOADED_DATA);
                if (uploadedMediaData) {
                    toast.loading("Taking you to AI summarizer...", {
                        id: "uploadingMedia",
                    });
                    const { mediaId, extension, bucket } = uploadedMediaData;

                    const hasAIUsagesLeft = verifyAIUsage();

                    await callAddMediaS3UserTag({
                        mediaId: `${mediaId}.${extension}`,
                        bucket,
                        startProcessing:
                            [ItemType.NOTE, ItemType.FLASHCARDSET].includes(
                                aiGenerationSettings?.contentType as ItemType
                            ) && hasAIUsagesLeft,
                    });
                    closeAuthSlide();

                    if (!hasAIUsagesLeft) {
                        toast.error("You have reached your AI usage limit. Please upgrade to continue.", {
                            id: "uploadingMedia",
                        });

                        return openUpgradePopup({
                            event: isFileVideo ? UpgradeEvent.MAX_VIDEO_AI : UpgradeEvent.MAX_PDF_AI,
                            context: {
                                itemId: mediaId,
                                itemType: ItemType.MEDIA,
                            },
                        });
                    }

                    toast.success("Your file has been uploaded!", {
                        id: "uploadingMedia",
                    });

                    setRedirectAfterLogin(
                        `/ai-media-${
                            aiGenerationSettings?.contentType === ItemType.FLASHCARDSET ? "flashcards" : "note"
                        }/${mediaId}`
                    );
                }

                // TEACHER TOOLS
                const teacherToolsData = await LocalKeyValueStore.find(AI_TEACHER_TOOLS_SETTINGS_LS_KEY + "_");

                await Promise.all(teacherToolsData.map(async ({ key }) => LocalKeyValueStore.remove(key)));
                if (teacherToolsData?.length) {
                    // just take 1 of them.
                    const { value: toolData } = teacherToolsData[0];
                    const { tool, noteId } = JSON.parse(toolData || "{}");
                    setRedirectAfterLogin(`/free-ai-tools-for-teachers/${TOOL_URL[tool]}/${noteId}`);
                }

                const landingPageNoteData = (await LocalKeyValueStore.getWithExpiry(
                    STORAGE_KEYS.LANDING_PAGE_NOTE_DATA
                )) as LandingPageNoteData;

                await LocalKeyValueStore.remove(STORAGE_KEYS.LANDING_PAGE_NOTE_DATA);
                if (landingPageNoteData) {
                    const { title, content } = landingPageNoteData;
                    closeAuthSlide();
                    await createAndOpenNewNote({ title, content }, user, router, {
                        autoCreateFlashcards: true,
                    });
                    return;
                }

                const landingPageQuizletImportLink = (await LocalKeyValueStore.getWithExpiry(
                    STORAGE_KEYS.LANDING_PAGE_QUIZLET_IMPORT_LINK
                )) as LandingPageQuizletImportLink;

                await LocalKeyValueStore.remove(STORAGE_KEYS.LANDING_PAGE_QUIZLET_IMPORT_LINK);
                if (landingPageQuizletImportLink) {
                    const { quizletUrl } = landingPageQuizletImportLink;
                    closeAuthSlide();
                    updateImportFromQuizletFlowStates({ quizletUrl, isPopupOpen: true });

                    return;
                }

                const landingPageVideoSummarizeLink = (await LocalKeyValueStore.getWithExpiry(
                    STORAGE_KEYS.LANDING_PAGE_VIDEO_SUMMARIZE_LINK
                )) as
                    | {
                          videoUrl: string;
                      }
                    | null
                    | undefined;

                await LocalKeyValueStore.remove(STORAGE_KEYS.LANDING_PAGE_VIDEO_SUMMARIZE_LINK);
                if (landingPageVideoSummarizeLink) {
                    const { videoUrl } = landingPageVideoSummarizeLink;
                    closeAuthSlide();
                    updateImportFromVideoSummarizeFlowStates({
                        videoUrl,
                        isPopupOpen: true,
                    });

                    return;
                }

                const initialSetupUrl = await getInitialSetupUrl({
                    user,
                    organization,
                });

                if (initialSetupUrl) {
                    closeAuthSlide();
                    // return early, we will do the redirect after initial account setup
                    return window.location.replace(initialSetupUrl);
                }

                redirectAfterLoginIfNeeded();
                closeAuthSlide();
            } catch {
                // ignore - ideally we want to show some suitable toast to the user
            }
        });
    }, [
        router,
        pathname,
        closeAuthSlide,
        openUpgradePopup,
        verifyAIUsage,
        createUnidaysCheckoutSession,
        updateImportFromQuizletFlowStates,
        updateImportFromVideoSummarizeFlowStates,
    ]);

    useEffect(() => {
        // on legacy browsers, we don't have BroadcastChannel.
        // https://caniuse.com/?search=BroadcastChannel
        if (typeof BroadcastChannel === "undefined") return;

        const interTabsAuthChannel = new BroadcastChannel("inter-tabs-auth-channel");

        const interTabsAuthChannelMessageHandler = async ({ data: _event }) => {
            await wait(1000); // wait a bit for things to settle down
            window.location.reload();
        };

        interTabsAuthChannel.addEventListener("message", interTabsAuthChannelMessageHandler);

        const intraTabAuthChannelUnsubscribe = Hub.listen("auth", async ({ payload: { event } }) => {
            if (event === "signedIn" || event === "signedOut") {
                interTabsAuthChannel.postMessage(event);
            }
        });

        return () => {
            interTabsAuthChannel.removeEventListener("message", interTabsAuthChannelMessageHandler);
            interTabsAuthChannel.close();
            intraTabAuthChannelUnsubscribe();
        };
    }, []);

    return (
        <div>
            <LazyLoaded load={importFromQuizletFlowStates.isPopupOpen}>
                <ImportFromQuizletPopupLandingPageFlow
                    quizletUrl={importFromQuizletFlowStates.quizletUrl}
                    isOpen={importFromQuizletFlowStates.isPopupOpen}
                    onClose={async () => {
                        updateImportFromQuizletFlowStates({ isPopupOpen: false });
                        await LocalKeyValueStore.remove("landingPageQuizletImportLink");
                    }}
                />
            </LazyLoaded>
            <LazyLoaded load={importFromVideoSummarizeFlowStates.isPopupOpen}>
                <SummarizeVideoFlowPopup
                    videoUrl={importFromVideoSummarizeFlowStates.videoUrl}
                    isOpen={importFromVideoSummarizeFlowStates.isPopupOpen}
                    onClose={async () => {
                        updateImportFromVideoSummarizeFlowStates({ isPopupOpen: false });
                        await LocalKeyValueStore.remove("landingPageVideoSummarizeLink");
                    }}
                />
            </LazyLoaded>
        </div>
    );
};
