import { AICompletionType } from "@/graphql/schema";
import { GRADES } from "@/hooks/grades/utils";
import { TOOL_URL } from "@/utils/aiTools";

const TextTypes = [
    { label: "Literary Nonfiction", value: "Literary Nonfiction" },
    { label: "Expository", value: "Expository" },
    { label: "Persuasive", value: "Persuasive" },
    { label: "Fiction", value: "Fiction" },
    { label: "Poem", value: "Poem" },
];

export const AI_TEACHER_TOOLS_SETTINGS_LS_KEY = "ai_tool_settings";

export enum AI_TOOLS_CATEGORIES {
    PLANNING = "Planning",
    SUMMARIZERS = "Summarizers",
    CONTENT = "Content",
    REVIEW = "Review",
    COMMUNICATION = "Communication",
}

export enum SortBySetting {
    Popularity = "Popaliraty",
    Recent = "Recent",
}

export const ESSAY_STYLE_OPTIONS = [
    {
        label: "Expository",
        value: "expository",
    },
    {
        label: "Narrative",
        value: "narrative",
    },
    {
        label: "Argumentative",
        value: "argumentative",
    },
    {
        label: "Compare and contrast",
        value: "compare and contrast",
    },
    {
        label: "Descriptive",
        value: "descriptive",
    },
    {
        label: "General",
        value: "general",
    },
];

export const SUBJECT_OPTIONS = [
    {
        label: "Biology",
        value: "Biology",
    },
    {
        label: "Chemistry",
        value: "Chemistry",
    },
    {
        label: "Physics",
        value: "Physics",
    },
    {
        label: "Earth Science",
        value: "Earth Science",
    },
    {
        label: "Environmental Science",
        value: "Environmental Science",
    },
    {
        label: "Astronomy",
        value: "Astronomy",
    },
    {
        label: "General Science",
        value: "General Science",
    },
];

export const SAFETY_LEVEL_OPTIONS = [
    {
        label: "Low",
        value: "Low",
    },
    {
        label: "Medium",
        value: "Medium",
    },
    {
        label: "High",
        value: "High",
    },
];

export const LAB_TYPE_OPTIONS = [
    {
        label: "Observation",
        value: "Observation",
    },
    {
        label: "Measurement",
        value: "Measurement",
    },
    {
        label: "Classification",
        value: "Classification",
    },
    {
        label: "Controlled Experiment",
        value: "Controlled experiment",
    },
    {
        label: "Data Analysis",
        value: "Data analysis",
    },
    {
        label: "Model Building",
        value: "Model Building",
    },
    {
        label: "Simulation",
        value: "Simulation",
    },
    {
        label: "Field Study",
        value: "Field study",
    },
];

export const TOOLS_SORTBY_SETTINGS = [
    {
        value: SortBySetting.Popularity,
        label: "Most Popular",
    },
    {
        value: SortBySetting.Recent,
        label: "Recent",
    },
];

const PARAPPHRASE_TONE_OPTIONS = [
    {
        label: "Formal",
        value: "Formal",
    },
    {
        label: "Casual",
        value: "Casual",
    },
    {
        label: "Professional",
        value: "Professional",
    },
    {
        label: "Academic",
        value: "Academic",
    },
    {
        label: "Creative",
        value: "Creative",
    },
    {
        label: "Technical",
        value: "Technical",
    },
    {
        label: "Conversational",
        value: "Conversational",
    },
];

const PROFESSIONAL_EMAIL_TONE_OPTIONS = [
    {
        label: "Formal",
        value: "FORMAL",
    },
    {
        label: "Casual",
        value: "CASUAL",
    },
    {
        label: "Conversational",
        value: "CONVERSATIONAL",
    },
    {
        label: "Encouraging",
        value: "ENCOURAGING",
    },
];
const SCENARIO_FORMAT_OPTIONS = [
    {
        label: "Traditional",
        value: "Traditional",
    },
    {
        label: "Oxford Style",
        value: "Oxford Style",
    },
    {
        label: "Lincoln-Douglas",
        value: "Lincoln-Douglas",
    },
    {
        label: "Cross-Examination",
        value: "Cross-Examination",
    },
    {
        label: "Parliamentary",
        value: "Parliamentary",
    },
    {
        label: "Moot Court",
        value: "Moot Court",
    },
    {
        label: "Public Forum",
        value: "Public Forum",
    },
];

const DEBATE_DIFFICULTY_OPTIONS = [
    {
        label: "Beginner",
        value: "Beginner",
    },
    {
        label: "Intermediate",
        value: "Intermediate",
    },
    {
        label: "Advanced",
        value: "Advanced",
    },
];

const ICE_BREAKER_SETTINGS_OPTIONS = [
    {
        label: "In-person",
        value: "In-person",
    },
    {
        label: "Virtual",
        value: "Virtual",
    },
];

const TEXT_LENGTH_OPTIONS = [
    {
        label: "Very Short",
        value: "very_short",
    },
    {
        label: "Short",
        value: "short",
    },
    {
        label: "Medium",
        value: "medium",
    },
    {
        label: "Long",
        value: "long",
    },
];

const DECODABLE_TEXT_PATTERN_OPTIONS = [
    { label: "m /m/ am", value: "m /m/ am" },
    { label: "t /t/ at, mat", value: "t /t/ at, mat" },
    { label: "s /s/ sat", value: "s /s/ sat" },
    { label: "p /p/ sat, tap", value: "p /p/ sat, tap" },
    { label: "I /ĭ/ sit, tip", value: "I /ĭ/ sit, tip" },
    { label: "f /f/ fit, if", value: "f /f/ fit, if" },
    { label: "n /n/ nip, tin", value: "n /n/ nip, tin" },
    { label: "o /ŏ/ on, pot", value: "o /ŏ/ on, pot" },
    { label: "d /d/ mad, dim", value: "d /d/ mad, dim" },
    { label: "r /r/ rat, rid", value: "r /r/ rat, rid" },
    { label: "u /ŭ/ pup, sum", value: "u /ŭ/ pup, sum" },
    { label: "c /k/ cod, cub", value: "c /k/ cod, cub" },
    { label: "g /g/ gut, dig", value: "g /g/ gut, dig" },
    { label: "b /b/ ban, bib", value: "b /b/ ban, bib" },
    { label: "e /ĕ/ set, bed", value: "e /ĕ/ set, bed" },
    { label: "h /h/ hem, hip", value: "h /h/ hem, hip" },
    { label: "l /l/ lid, lag", value: "l /l/ lid, lag" },
    { label: "k /k/ kid, kin", value: "k /k/ kid, kin" },
    { label: "j /j/ jug, jam", value: "j /j/ jug, jam" },
    { label: "w /w/ wag, wit", value: "w /w/ wag, wit" },
    { label: "y /y/ yam, yet", value: "y /y/ yam, yet" },
    { label: "x /ks/ ax, fox", value: "x /ks/ ax, fox" },
    { label: "v /v/ van, vex", value: "v /v/ van, vex" },
    { label: "z /z/ zap, zit", value: "z /z/ zap, zit" },
    { label: "ck /k/ duck, lock", value: "ck /k/ duck, lock" },
    { label: "qu /kw/ quack, quiz", value: "qu /kw/ quack, quiz" },
    { label: "-s /s/ pets, fits", value: "-s /s/ pets, fits" },
    { label: "-s /z/ bans, runs", value: "-s /z/ bans, runs" },
    { label: "k /k/ blends skid, tusk", value: "k /k/ blends skid, tusk" },
    { label: "r /r/ blends crab, prop", value: "r /r/ blends crab, prop" },
    { label: "l /l/ blends flag, belt", value: "l /l/ blends flag, belt" },
    { label: "sh /sh/ wish, shed", value: "sh /sh/ wish, shed" },
    { label: "ch /ch/ chin, inch", value: "ch /ch/ chin, inch" },
    { label: "ng /ng/ song, bring", value: "ng /ng/ song, bring" },
    { label: "nk /nk/ bank, drink", value: "nk /nk/ bank, drink" },
    { label: "Voiced th /th/ thus, them", value: "Voiced th /th/ thus, them" },
    { label: "Voiceless th /th/ thin, with", value: "Voiceless th /th/ thin, with" },
    {
        label: "Double FLSZ Rule (ff, ll, ss, zz) tell, cliff, moss, buzz",
        value: "Double FLSZ Rule (ff, ll, ss, zz) tell, cliff, moss, buzz",
    },
    { label: "-all, -oll, -ull hall, troll, pull, small", value: "-all, -oll, -ull hall, troll, pull, small" },
    { label: "-es, CH/SH/S/X/Z boxes, buses, taxes, wishes", value: "-es, CH/SH/S/X/Z boxes, buses, taxes, wishes" },
    { label: "ee /ē/ bee, feet, green", value: "ee /ē/ bee, feet, green" },
    { label: "a_e /ã/ late, game, maze", value: "a_e /ã/ late, game, maze" },
    { label: "i_e /ī/ line, bride, pine", value: "i_e /ī/ line, bride, pine" },
    { label: "o_e /ō/ code, vote, woke", value: "o_e /ō/ code, vote, woke" },
    { label: "e_e /ē/ eve, mere, these", value: "e_e /ē/ eve, mere, these" },
    { label: "u_e /ū/, /yū/ cube, rule", value: "u_e /ū/, /yū/ cube, rule" },
    { label: "_ce /s/ ice, trace", value: "_ce /s/ ice, trace" },
    { label: "_ge /j/ huge, stage", value: "_ge /j/ huge, stage" },
    { label: "wh /w/ whim, wheel", value: "wh /w/ whim, wheel" },
    { label: "VCe exceptions none, live, some", value: "VCe exceptions none, live, some" },
    { label: "tch /ch/ hatch, stitch", value: "tch /ch/ hatch, stitch" },
    { label: "dge /j/ fudge, ridge", value: "dge /j/ fudge, ridge" },
    {
        label: "Long VCC (-ild, -old, -ind, -olt, -ost) mild, mind, fold, host, colt",
        value: "Long VCC (-ild, -old, -ind, -olt, -ost) mild, mind, fold, host, colt",
    },
    { label: "Compound Words bedrock, sunset", value: "Compound Words bedrock, sunset" },
    { label: "Closed/Closed anvil, pigment", value: "Closed/Closed anvil, pigment" },
    { label: "Open/Closed humid, begin", value: "Open/Closed humid, begin" },
    { label: "-ed smushed, granted", value: "-ed smushed, granted" },
    { label: "-ing falling, singing", value: "-ing falling, singing" },
    { label: "y /ī/ sky, try", value: "y /ī/ sky, try" },
    { label: "y /ē/ jelly, lady", value: "y /ē/ jelly, lady" },
    { label: "-le table, candle", value: "-le table, candle" },
    { label: "a /ŏ/ watch, squat", value: "a /ŏ/ watch, squat" },
    { label: "ai, ay /ā/ sail, tray, contain", value: "ai, ay /ā/ sail, tray, contain" },
    { label: "ea, ey /ē/ peak, honey", value: "ea, ey /ē/ peak, honey" },
    { label: "oa, ow, oe /ō/ oak, bow, foe", value: "oa, ow, oe /ō/ oak, bow, foe" },
    { label: "ie, igh /ī/ pie, highway, night", value: "ie, igh /ī/ pie, highway, night" },
    { label: "oo, u /oo/ took, push", value: "oo, u /oo/ took, push" },
    { label: "oo /ū/ doom, cartoon", value: "oo /ū/ doom, cartoon" },
    { label: "oy, oi /oi/ coil, joy", value: "oy, oi /oi/ coil, joy" },
    { label: "ou, ow /ow/ bound, eyebrow", value: "ou, ow /ow/ bound, eyebrow" },
    { label: "ew, ui, ue /ū/ stew, juice", value: "ew, ui, ue /ū/ stew, juice" },
    { label: "au, aw, augh /aw/ pause, seesaw", value: "au, aw, augh /aw/ pause, seesaw" },
    { label: "ea /ĕ/ bread, heavy", value: "ea /ĕ/ bread, heavy" },
    { label: "ar /ar/ carpet, far", value: "ar /ar/ carpet, far" },
    { label: "or, ore /or/ explore, stormy", value: "or, ore /or/ explore, stormy" },
    { label: "er /er/ perk, letter", value: "er /er/ perk, letter" },
    { label: "ir, ur /er/ third, murmur", value: "ir, ur /er/ third, murmur" },
    { label: "-ar, -or /er/ collar, doctor", value: "-ar, -or /er/ collar, doctor" },
    { label: "kn /n/ knit, knows", value: "kn /n/ knit, knows" },
    { label: "wr /r/ wrinkle, write", value: "wr /r/ wrinkle, write" },
    { label: "mb /m/ numb, lamb", value: "mb /m/ numb, lamb" },
    { label: "ph /f/ graph, photo", value: "ph /f/ graph, photo" },
    { label: "Silent T st /s/ castle, whistle", value: "Silent T st /s/ castle, whistle" },
    { label: "-s/-es trees, smushes", value: "-s/-es trees, smushes" },
    { label: "-er/-est longer, longest", value: "-er/-est longer, longest" },
    { label: "Doubling Rule -ed, -ing hugged, hugging", value: "Doubling Rule -ed, -ing hugged, hugging" },
    { label: "Doubling Rule -er, -est taller, tallest", value: "Doubling Rule -er, -est taller, tallest" },
    { label: "Drop -e Rule closer, smiling", value: "Drop -e Rule closer, smiling" },
    { label: "-ly currently, gladly", value: "-ly currently, gladly" },
    { label: "-less, -ful harmless, eventful", value: "-less, -ful harmless, eventful" },
    { label: "un- unlucky, unable", value: "un- unlucky, unable" },
    { label: "pre-, re- preheat, rewind", value: "pre-, re- preheat, rewind" },
    { label: "dis- disenroll, discredit", value: "dis- disenroll, discredit" },
    { label: "-y to ies studies, berries", value: "-y to ies studies, berries" },
    { label: "-sion, -tion television, action", value: "-sion, -tion television, action" },
    { label: "-ture capture, signature", value: "-ture capture, signature" },
    { label: "-er, -or, -ist teacher, artist, inspector", value: "-er, -or, -ist teacher, artist, inspector" },
    { label: "-ish childish, skittish", value: "-ish childish, skittish" },
    { label: "-ness calmness, kindness", value: "-ness calmness, kindness" },
    { label: "-ment arrangement, judgment", value: "-ment arrangement, judgment" },
    { label: "-able, -ible flexible, dependable", value: "-able, -ible flexible, dependable" },
    { label: "air, are, ear /air/ dare, hair, swear", value: "air, are, ear /air/ dare, hair, swear" },
    { label: "ear /ear/ ear, yearbook", value: "ear /ear/ ear, yearbook" },
    {
        label: "Alternate Long A /ā/ (ei, ey, eigh, aigh, ea) eight, vein, convey, prey, steak",
        value: "Alternate Long A /ā/ (ei, ey, eigh, aigh, ea) eight, vein, convey, prey, steak",
    },
    {
        label: "Alternate Long U (ew, eu, ue /yū/; ou /ū/) argue, skew, value",
        value: "Alternate Long U (ew, eu, ue /yū/; ou /ū/) argue, skew, value",
    },
    { label: "ough /aw/, /ō/ thoughtful, doughnut", value: "ough /aw/, /ō/ thoughtful, doughnut" },
    { label: "c /s/ city, excel", value: "c /s/ city, excel" },
    { label: "g /j/ germ, urgent", value: "g /j/ germ, urgent" },
    { label: "Alternate CH /sh/, /k/ chaos, Chicago", value: "Alternate CH /sh/, /k/ chaos, Chicago" },
];

export type AI_TOOL = {
    title: string;
    description: string;
    rating: number;
    category: AI_TOOLS_CATEGORIES;
    tool: AICompletionType;
    color: string;
    href?: string;
    isPopular?: boolean;
};

export const AI_TOOLS: Partial<Record<AICompletionType, AI_TOOL>> = {
    [AICompletionType.LESSON_PLAN]: {
        color: "#EBE9F2",
        title: "Lesson Plan",
        description: "Generate a lesson plan for a topic or objective you’re teaching.",
        rating: 4,
        category: AI_TOOLS_CATEGORIES.PLANNING,
        tool: AICompletionType.LESSON_PLAN,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.LESSON_PLAN]}`,
        isPopular: true,
    },
    [AICompletionType.RUBRIC_GENERATOR]: {
        color: "#E2EAF6",
        title: "Rubric Generator",
        description: "Have AI write a rubric for an assignment you are creating for your class in a table format.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.PLANNING,
        tool: AICompletionType.RUBRIC_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.RUBRIC_GENERATOR]}`,
    },
    [AICompletionType.WRITING_FEEDBACK]: {
        color: "#FFE6C1",
        title: "Writing Feedback",
        description: "Based on a custom criteria, have AI give areas of strength & areas for growth on student work.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.REVIEW,
        tool: AICompletionType.WRITING_FEEDBACK,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.WRITING_FEEDBACK]}`,
    },
    [AICompletionType.READING_TEXT]: {
        color: "#EBF2DF",
        title: "Reading Text",
        description: "Generate original informational texts for your class, customized to the topic of your choice.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.READING_TEXT,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.READING_TEXT]}`,
    },
    [AICompletionType.WORKSHEET_GENERATOR]: {
        color: "#D7D7D7",
        title: "Worksheet Generator",
        description: "Generate a worksheet based on any topic or text.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.WORKSHEET_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.WORKSHEET_GENERATOR]}`,
        isPopular: true,
    },
    [AICompletionType.TEXT_LEVELER]: {
        color: "#E2F6F1",
        title: "Text/File Leveler",
        description: "Take any text and adapt it for any grade level to fit a student's reading level / skills.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.REVIEW,
        tool: AICompletionType.TEXT_LEVELER,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.TEXT_LEVELER]}`,
        isPopular: true,
    },
    [AICompletionType.PROFESSIONAL_EMAIL]: {
        color: "#E2E7F6",
        title: "Professional Email",
        description: "We'll help you write a professional email to colleagues, family, or other professionals",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.COMMUNICATION,
        tool: AICompletionType.PROFESSIONAL_EMAIL,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.PROFESSIONAL_EMAIL]}`,
    },
    [AICompletionType.REPORT_CARD_COMMENTS]: {
        color: "#E7E7E7",
        title: "Report Card Comments",
        description: "Generate report card comments with a student's strengths and areas for growth.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.COMMUNICATION,
        tool: AICompletionType.REPORT_CARD_COMMENTS,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.REPORT_CARD_COMMENTS]}`,
    },
    [AICompletionType.TEXT_PROOFREADER]: {
        color: "#E0D7D7",
        title: "Text Proofreader",
        description:
            "Take any text and have it proofread, correcting grammar, spelling, punctuation and adding clarity.",
        rating: 4,
        category: AI_TOOLS_CATEGORIES.REVIEW,
        tool: AICompletionType.TEXT_PROOFREADER,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.TEXT_PROOFREADER]}`,
    },
    [AICompletionType.TEXT_PARAPHRASER]: {
        color: "#F1ECFF",
        title: "Text Paraphraser",
        description: "Rewrite text in various styles while maintaining the core message and meaning.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.REVIEW,
        tool: AICompletionType.TEXT_PARAPHRASER,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.TEXT_PARAPHRASER]}`,
    },
    [AICompletionType.MAKE_IT_RELEVANT]: {
        color: "#FFE2E8",
        title: "Make It Relevant",
        description:
            "Generate fun ideas that make what you’re teaching relevant to your specific class based on their interests and background.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.REVIEW,
        tool: AICompletionType.MAKE_IT_RELEVANT,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.MAKE_IT_RELEVANT]}`,
    },
    [AICompletionType.CLASS_NEWSLETTER_GENERATOR]: {
        color: "#D9F2F8",
        title: "Class Newsletter",
        description: "Generate a newsletter to share class updates.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.CLASS_NEWSLETTER_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.CLASS_NEWSLETTER_GENERATOR]}`,
    },
    [AICompletionType.VOCABULARY_LIST_GENERATOR]: {
        color: "#F7E8D2",
        title: "Vocabulary List",
        description:
            "Generate customized vocabulary lists with definitions, example sentences, and optional additional information for various subjects and grade levels",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.VOCABULARY_LIST_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.VOCABULARY_LIST_GENERATOR]}`,
    },
    [AICompletionType.DISCUSSION_PROMPT_GENERATOR]: {
        color: "#DEF8E3",
        title: "Discussion Prompt",
        description: "Craft engaging prompts to stimulate meaningful classroom discussion.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.DISCUSSION_PROMPT_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.DISCUSSION_PROMPT_GENERATOR]}`,
    },
    [AICompletionType.WRITING_PROMPT_GENERATOR]: {
        color: "#E6E1D8",
        title: "Writing Prompt",
        description: "Create a writing prompt for your students based on the topic you're teaching.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.WRITING_PROMPT_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.WRITING_PROMPT_GENERATOR]}`,
    },
    [AICompletionType.MATH_WORD_PROBLEM_GENERATOR]: {
        color: "#F2D3D4",
        title: "Math Word Problem",
        description: "Write a custom math word / story problem based on the concept you’re teaching and a story topic.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.MATH_WORD_PROBLEM_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.MATH_WORD_PROBLEM_GENERATOR]}`,
    },
    [AICompletionType.SCIENCE_LAB_GENERATOR]: {
        color: "#E2F5D7",
        title: "Science Lab",
        description: "Enter the science topics then watch as our AI tool will create an outline in real time.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.SCIENCE_LAB_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.SCIENCE_LAB_GENERATOR]}`,
    },
    // [AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR]: {
    //     color: "#F5EBF7",
    //     title: "Multiple Choice Quiz",
    //     description: "Generate a quiz on any subject or topic.",
    //     rating: 3,
    //     category: AI_TOOLS_CATEGORIES.CONTENT,
    //     tool: AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR,
    // },
    [AICompletionType.DEBATE_SCENARIO_GENERATOR]: {
        color: "#F6EEDC",
        title: "Debate Scenario",
        description:
            "Produce balanced, thought-provoking debate scenarios with background information, argument starters, and guiding questions for various subjects.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.DEBATE_SCENARIO_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.DEBATE_SCENARIO_GENERATOR]}`,
    },

    // V2 Tools:
    [AICompletionType.ICE_BREAKER_ACTIVITIES]: {
        color: "#CFD8F1",
        title: "Ice Breaker Activities",
        description:
            "Create funny, thought-provoking ice breaker questions to energize every classroom, no matter the grade.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.ICE_BREAKER_ACTIVITIES,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.ICE_BREAKER_ACTIVITIES]}`,
    },
    [AICompletionType.CONTENT_SUMMARIZER]: {
        color: "#F8E2FA",
        title: "Content Summarizer",
        description: "Save time and simplify your lesson prep by summarizing materials at the click of a button.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.CONTENT_SUMMARIZER,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.CONTENT_SUMMARIZER]}`,
    },
    [AICompletionType.JEOPARDY_GAME_GENERATOR]: {
        color: "#E6F4FB",
        title: "Jeopardy Game",
        description: "Easily create and customize fun, interactive Jeopardy-style games for all students and subjects!",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.JEOPARDY_GAME_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.JEOPARDY_GAME_GENERATOR]}`,
    },
    [AICompletionType.DECODABLE_TEXT_GENERATOR]: {
        color: "#F3D8C6",
        title: "Decodable Text",
        description:
            "Generate decodable text passages, sentences, and stories instantly with AI for structured phonics-based reading instruction.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.DECODABLE_TEXT_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.DECODABLE_TEXT_GENERATOR]}`,
    },
    [AICompletionType.STANDARDS_UNPACKER]: {
        color: "#C1E5F6",
        title: "Standards Unpacker",
        description: "Quickly break down educational standards into clear, actionable learning objectives",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.STANDARDS_UNPACKER,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.STANDARDS_UNPACKER]}`,
    },
    [AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]: {
        color: "#E2D9FA",
        title: "Math Spiral Review",
        description:
            "Effortlessly create spiral review math worksheets, activities, and assessments to reinforce learning and mastery.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]}`,
    },
    [AICompletionType.JOKE_GENERATOR]: {
        color: "#E8D0BB",
        title: "Joke Generator",
        description:
            "One Sentence Description: Engage your students with fun jokes for kids, including knock knock jokes and jokes of the day!",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.JOKE_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.JOKE_GENERATOR]}`,
    },
    [AICompletionType.TEXT_TRANSLATOR]: {
        color: "#F8D4E9",
        title: "Text Translator",
        description:
            "Translate text instantly with AI—accurate, fast, and free language translation for English, Spanish, and beyond!",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.TEXT_TRANSLATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.TEXT_TRANSLATOR]}`,
    },
    [AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]: {
        color: "#D6E4FD",
        title: "Story Word Problems",
        description: " Instantly generate customized story problems for math, science, and other subjects.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.STORY_WORD_PROBLEMS_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]}`,
    },
    [AICompletionType.BIP_GENERATOR]: {
        color: "#F6EAD8",
        title: "BIP Generator",
        description:
            "Instantly generate effective, personalized behavior intervention plans (BIP) for students using AI-driven insights.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.BIP_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.BIP_GENERATOR]}`,
    },
    [AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]: {
        color: "#F4E2E2",
        title: "Time Based Activity",
        description:
            "Engage students with fun, educational, and interactive classroom games and classroom activities for every subject and grade level.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.TIME_BASED_ACTIVITY_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]}`,
    },
    [AICompletionType.RECOMMENDATION_LETTER_GENERATOR]: {
        color: "#E7ECF3",
        title: "Recommendation Letter",
        description:
            "Effortlessly create professional, personalized letters of recommendation that dazzle colleges, employers, and more. ",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.RECOMMENDATION_LETTER_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.RECOMMENDATION_LETTER_GENERATOR]}`,
    },
    [AICompletionType.PERFORMANCE_TASK_GENERATOR]: {
        color: "#D4F2D3",
        title: "Performance Task",
        description:
            "Create structured performance tasks and assessments instantly that align with SBAC questioning framework.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.PERFORMANCE_TASK_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.PERFORMANCE_TASK_GENERATOR]}`,
    },
    [AICompletionType.PLC_AGENDA_GENERATOR]: {
        color: "#EEECE8",
        title: "PLC Agenda",
        description: "Make PLC meetings more organized with PLC meeting agendas designed for teachers and schools.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.PLC_AGENDA_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.PLC_AGENDA_GENERATOR]}`,
    },
    [AICompletionType.SYLLABUS_GENERATOR]: {
        color: "#F2E1F5",
        title: "Syllabus",
        description:
            "Streamline your lesson planning with an AI syllabus maker that helps you build structured, organized, and effective course outlines in seconds.",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.SYLLABUS_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.SYLLABUS_GENERATOR]}`,
    },
    [AICompletionType.SONG_GENERATOR]: {
        color: "#F2F3DD",
        title: "Song Generator",
        description:
            "Engage students and enhance classroom creativity with Knowt’s AI song generator, perfect to bring musical fun to any classroom setting. ",
        rating: 3,
        category: AI_TOOLS_CATEGORIES.CONTENT,
        tool: AICompletionType.SONG_GENERATOR,
        href: `/free-ai-tools-for-teachers/${TOOL_URL[AICompletionType.SONG_GENERATOR]}`,
    },
};

export const AI_LANDING_TOOLS: Partial<Record<AICompletionType, AI_TOOL>> = {
    [AICompletionType.PDF_SUMMARIZER]: {
        color: "#E2EDF6",
        title: "AI PDF Summarizer",
        description: "Upload a PDF to get notes & flashcards created from it instantly.",
        rating: 5,
        category: AI_TOOLS_CATEGORIES.SUMMARIZERS,
        tool: AICompletionType.PDF_SUMMARIZER,
        href: "/ai-pdf-summarizer",
        isPopular: true,
    },
    [AICompletionType.VIDEO_SUMMARIZER]: {
        color: "#F6EEE2",
        title: "AI Video Summarizer",
        description: "Upload a video to get notes & flashcards created from it instantly.",
        rating: 5,
        category: AI_TOOLS_CATEGORIES.SUMMARIZERS,
        tool: AICompletionType.VIDEO_SUMMARIZER,
        href: "/ai-video-summarizer",
    },
    [AICompletionType.LECTURE_NOTE_TAKER]: {
        color: "#F6E2E2",
        title: "Live Lecture Note Taker",
        description: "Start a recording of your class lecture and get notes & flashcards",
        rating: 5,
        category: AI_TOOLS_CATEGORIES.SUMMARIZERS,
        tool: AICompletionType.LECTURE_NOTE_TAKER,
        href: "/ai-lecture-note-taker",
        isPopular: true,
    },
    [AICompletionType.PPT_SUMMARIZER]: {
        color: "#EDE2F6",
        title: "AI PPT Summarizer",
        description: "Upload a PPT or slides file to get notes & flashcards created from it instantly.",
        rating: 5,
        category: AI_TOOLS_CATEGORIES.SUMMARIZERS,
        tool: AICompletionType.PPT_SUMMARIZER,
        href: "/ai-powerpoint-summarizer",
        isPopular: true,
    },
};

export const AI_TOOLS_SETTINGS_H1_TITLES = {
    [AICompletionType.LESSON_PLAN]: "Free AI Lesson Plan Generator",
    [AICompletionType.TEXT_PROOFREADER]: "Free AI Text Proofreader",
    [AICompletionType.RUBRIC_GENERATOR]: "Free AI Rubric Maker",
    [AICompletionType.WRITING_FEEDBACK]: "Free AI Writing Feedback Generator",
    [AICompletionType.WORKSHEET_GENERATOR]: "Free AI Worksheet Maker",
    [AICompletionType.TEXT_LEVELER]: "Free AI Text Leveler",
    [AICompletionType.REPORT_CARD_COMMENTS]: "Free AI Report Card Comments Generator",
    [AICompletionType.PROFESSIONAL_EMAIL]: "Free AI Email Writer",
    [AICompletionType.READING_TEXT]: "Free AI Reading Text Generator",
    [AICompletionType.TEXT_PARAPHRASER]: "Free AI Paraphrasing Tool for Teachers",
    [AICompletionType.MAKE_IT_RELEVANT]: "Make it Relevant",
    [AICompletionType.CLASS_NEWSLETTER_GENERATOR]: "Free AI Newsletter Generator for Classrooms",
    [AICompletionType.VOCABULARY_LIST_GENERATOR]: "Free AI Vocabulary List Generator",
    [AICompletionType.DISCUSSION_PROMPT_GENERATOR]: "Free AI Discussion Topic Generator",
    [AICompletionType.WRITING_PROMPT_GENERATOR]: "Free AI Writing Prompt Generator",
    [AICompletionType.MATH_WORD_PROBLEM_GENERATOR]: "Free AI Math Problem Generator",
    [AICompletionType.SCIENCE_LAB_GENERATOR]: "AI Science Experiment Idea Generator",
    [AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR]: "Free AI Multiple Choice Quiz Generator",
    [AICompletionType.DEBATE_SCENARIO_GENERATOR]: "AI Debate Topic Generator",
    [AICompletionType.ICE_BREAKER_ACTIVITIES]: "Free AI Ice Breaker Generator",
    [AICompletionType.CONTENT_SUMMARIZER]: "Free AI Text Summarizer",
    [AICompletionType.JEOPARDY_GAME_GENERATOR]: "Free AI Jeopardy Game Maker",
    [AICompletionType.DECODABLE_TEXT_GENERATOR]: "Free AI Decodable Text Generator",
    [AICompletionType.STANDARDS_UNPACKER]: "Free AI Standards Unpacker",
    [AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]: "Free AI Grader for Teachers",
    [AICompletionType.JOKE_GENERATOR]: "Free AI Joke Generator",
    [AICompletionType.TEXT_TRANSLATOR]: "Free AI Language Translator",
    [AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]: "Free AI Story Problem Generator",
    [AICompletionType.BIP_GENERATOR]: "Free AI Behavior Intervention Plan Generator",
    [AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]: "Free AI Classroom Activity Generator",
    [AICompletionType.RECOMMENDATION_LETTER_GENERATOR]: "Free AI Letter of Recommendation Generator",
    [AICompletionType.PERFORMANCE_TASK_GENERATOR]: "Free AI Performance Assessment Generator",
    [AICompletionType.PLC_AGENDA_GENERATOR]: "Free AI PLC Agenda Generator",
    [AICompletionType.SYLLABUS_GENERATOR]: "Free AI Syllabus Generator",
    [AICompletionType.SONG_GENERATOR]: "Free AI Song Generator",
};

export type FormInputDescriptor = {
    type:
        | "select"
        | "textInput"
        | "textarea"
        | "checkbox"
        | "radio"
        | "slider"
        | "number"
        | "textLength"
        | "file"
        | "singleLineOfText"
        | "boolean"
        | "hiddenForNow";
    name: string; // The key in the parameters
    label: string;
    placeholder?: string;
    options?: { value: string | boolean; label: string }[];
    required?: boolean;
    defaultValue?: any;
    validations?: (value: any) => string | null;
    unit?: string;
    min?: number;
    max?: number;
    layout?: {
        horizontal?: boolean;
    };
    disableAI?: boolean;
};

export const gradesMap = GRADES.map(grade => {
    let label = grade;
    if (/^\d+(st|nd|rd|th)$/.test(grade)) {
        label = `${grade} grade`;
    }

    return {
        label,
        value: grade,
    };
});

export const AI_TOOLS_FORM_INPUTS: {
    [key in AICompletionType]?: FormInputDescriptor[];
} = {
    [AICompletionType.LESSON_PLAN]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic, standard, or objective",
            placeholder:
                "Topic, standard, or longer description of what you’re teaching. For example, “HS-PS1-1 Use the periodic table as a model to predict the relative properties of elements based on the patterns of electrons",
            required: true,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
        {
            type: "number",
            name: "time",
            label: "Class Duration",
            required: true,
            unit: "min",
            min: 0,
            max: 360,
        },
        {
            type: "textarea",
            name: "additionalCriteria",
            label: "Additional criteria",
            placeholder: "Students are in a unit about world regions",
            required: false,
        },
        {
            type: "textarea",
            name: "standards",
            label: "Standard sets to align to",
            placeholder: "Any standards worldwide (CCSS, TEKS, Ontario, Florida)",
            required: false,
        },
        {
            type: "textarea",
            name: "classroomContext",
            label: "Classroom context",
            placeholder: "Optional: Enter classroom context for example class size, access to technology, etc",
            required: false,
        },
    ],
    [AICompletionType.TEXT_PROOFREADER]: [
        {
            type: "textarea",
            name: "text",
            label: "Text",
            placeholder: "Enter text here",
            required: true,
        },
        {
            type: "textarea",
            name: "additionalInfo",
            label: "Additional criteria",
            placeholder: "Students are in a unit about world regions",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.RUBRIC_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "select",
            options: [
                {
                    value: "3",
                    label: "3",
                },
                {
                    value: "4",
                    label: "4",
                },
                {
                    value: "5",
                    label: "5",
                },
                {
                    value: "6",
                    label: "6",
                },
                {
                    value: "7",
                    label: "7",
                },
                {
                    value: "8",
                    label: "8",
                },
                {
                    value: "9",
                    label: "9",
                },
                {
                    value: "10",
                    label: "10",
                },
            ],
            name: "pointScale",
            label: "Points scale",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "objective",
            label: "Assignment description",
            placeholder:
                "Topic, standard, or longer description of what you’re teaching. For example, “HS-PS1-1 Use the periodic table as a model to predict the relative properties of elements based on the patterns of electrons",
            required: true,
        },
        {
            type: "textarea",
            name: "description",
            label: "Standard sets to align to",
            placeholder: "Any standards worldwide (CCSS, TEKS, Ontario, Florida)",
            required: true,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
        {
            type: "textarea",
            name: "additionalInfo",
            label: "Additional criteria",
            placeholder: "Students are in a unit about world regions",
            required: false,
        },
    ],
    [AICompletionType.WRITING_FEEDBACK]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "description",
            label: "Describe the assignment",
            placeholder: "Tell us what prompt or guidelines you gave your students for this assignment",
            required: true,
        },
        {
            type: "textarea",
            name: "text",
            label: "Insert the writing you want feedback on",
            placeholder: "Add your text you want feed back on here.",
            required: true,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
        {
            type: "textarea",
            name: "rubric",
            label: "Type of feedback or rubric",
            placeholder:
                "Paste your rubric in here or tell us what kind of feedback you're looking to give your students",
            required: false,
        },
        {
            type: "textarea",
            name: "additionalInfo",
            label: "Additional instructions for the type of feedback",
            placeholder: "Students are in a unit about world regions",
            required: false,
        },
    ],
    [AICompletionType.READING_TEXT]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textLength",
            options: TEXT_LENGTH_OPTIONS,
            name: "textLength",
            label: "Text Length",
            placeholder: "Enter Text Length",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "select",
            options: TextTypes,
            name: "textType",
            label: "Type of Reading Passage",
            placeholder: "Enter Text Length",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic (be as specific as possible)",
            placeholder:
                "Topic, standard, or longer description of what you’re teaching. For example, “HS-PS1-1 Use the periodic table as a model to predict the relative properties of elements based on the patterns of electrons",
            required: true,
        },
        {
            type: "textarea",
            name: "additionalInfo",
            label: "Additional instructions",
            placeholder: "Students are in a unit about world regions",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.WORKSHEET_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic or text:",
            placeholder: "Write a topic (e.g.  World War II, Periodic table) or paste text to base the worksheet on.",
            required: true,
        },
        {
            type: "textarea",
            name: "standards",
            label: "Standards",
            placeholder: "Any standards worldwide (CCSS, TEKS, Ontario, Florida)",
            required: false,
        },
        {
            type: "textarea",
            name: "additionalInfo",
            label: "Additional instructions",
            placeholder: "Include any additional instructions for the worksheet",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
        {
            type: "number",
            name: "lengthOfWorksheet",
            placeholder: "Enter the length of the worksheet",
            label: "Length of Worksheet",
            required: true,
            unit: "min",
            min: 0,
            max: 360,
        },
    ],
    [AICompletionType.TEXT_LEVELER]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "text",
            label: "Text",
            placeholder: "Paste the text you want to change here.",
            required: true,
        },
        {
            type: "textarea",
            name: "standards",
            label: "Standards",
            placeholder: "Any standards worldwide (CCSS, TEKS, Ontario, Florida)",
            required: false,
        },
        {
            type: "textarea",
            name: "classroomContext",
            label: "Classroom context",
            placeholder: "Include any additional instructions for the text leveler",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.REPORT_CARD_COMMENTS]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textInput",
            name: "studentName",
            label: "Student name",
            placeholder: "Jane Smith",
            required: true,
        },
        {
            type: "textInput",
            name: "studentPronouns",
            label: "Student prononuns",
            placeholder: "he/she/ they, etc.",
            required: true,
        },
        {
            type: "textarea",
            name: "strengths",
            label: "Area of strength",
            placeholder: "What does the student excel at? Examples: writing, leadership",
            required: true,
        },
        {
            type: "textarea",
            name: "areasForImprovement",
            label: "Areas for growth",
            placeholder: "Where could this student improve? Examples: math concepts, collaboration",
            required: true,
        },
        {
            type: "textarea",
            name: "classroomContext",
            label: "Classroom context",
            placeholder: "What is the classroom context?",
            required: false,
        },
        {
            type: "textInput",
            name: "tone",
            label: "Tone",
            placeholder: "Enter the tone",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.PROFESSIONAL_EMAIL]: [
        {
            type: "textInput",
            name: "author",
            label: "Your name",
            placeholder: "Jane smith",
            required: true,
        },
        {
            type: "textInput",
            name: "recipient",
            label: "Recipient name",
            placeholder: "Jane smith",
            required: true,
        },
        {
            type: "select",
            options: PROFESSIONAL_EMAIL_TONE_OPTIONS,
            name: "tone",
            label: "Tone",
            required: true,
        },
        {
            type: "select",
            options: [
                { value: "FAMILY", label: "Family" },
                { value: "COLLEAGUE", label: "Colleague" },
                { value: "TEACHER", label: "Teacher" },
            ],
            name: "relationShipToRecipient",
            label: "Relationship to recipient",
            required: true,
        },
        {
            type: "textarea",
            name: "purpose",
            label: "Tell us what to include",
            placeholder:
                "Add key points or details to include in the email.\nExamples: 'Meeting scheduled for 3 PM, send agenda to all participants.'\n'Follow-up on our discussion about next steps for the project.'",
            required: true,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
        {
            type: "textarea",
            name: "includedContent",
            label: "Included content",
            placeholder: "Include any additional content to include in the email",
            required: false,
        },
        {
            type: "hiddenForNow",
            name: "tone",
            label: "Tone",
            placeholder: "Jane smith",
            required: false,
        },
        {
            type: "textarea",
            name: "additionalInfo",
            label: "Additional instructions",
            placeholder: "Include any additional instructions for the email",
            required: false,
        },
    ],
    [AICompletionType.TEXT_PARAPHRASER]: [
        {
            type: "textarea",
            name: "originalText",
            label: "Original text",
            placeholder: "Enter your original text",
            required: true,
        },
        {
            type: "textarea",
            name: "rewriteInstructions",
            label: "Rewrite instructions",
            placeholder: "Enter your rewrite instructions",
            required: true,
        },
        {
            type: "select",
            options: PARAPPHRASE_TONE_OPTIONS,
            name: "toneOptions",
            label: "Tone Options",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "boolean",
            name: "preserveKeyTerms",
            label: "Preserve key terms",
            required: true,
        },
        {
            type: "boolean",
            name: "maintainLength",
            label: "Maintain length",
            required: true,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.MAKE_IT_RELEVANT]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "What You’re teaching",
            placeholder: "What You’re teaching",
            required: true,
        },
        {
            type: "textarea",
            name: "interests",
            label: "Describe your students",
            placeholder: "Describe your students",
            required: true,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.CLASS_NEWSLETTER_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "importantAnnouncements",
            label: "Important announcements",
            placeholder: "Important announcements",
            required: true,
        },
        {
            type: "textarea",
            name: "recentCelebrations",
            label: "Recent celebrations",
            placeholder: "Recent celebrations",
            required: true,
        },
        {
            type: "textarea",
            name: "additionalInfo",
            label: "Any additional info",
            placeholder: "Any additional info",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.VOCABULARY_LIST_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
        {
            type: "number",
            name: "numberOfWords",
            label: "Number Of Words",
            required: true,
            max: 1000,
        },
        {
            type: "boolean",
            name: "includeExample",
            label: "Include Example",
            required: true,
        },
        {
            type: "boolean",
            name: "includeDefinitions",
            label: "Include Definitions",
            required: true,
        },
        {
            type: "boolean",
            name: "includePartOfSpeech",
            label: "Include Part Of Speech",
            required: true,
        },
        {
            type: "boolean",
            name: "includeSynonyms",
            label: "Include Synonyms",
            required: true,
        },
        {
            type: "boolean",
            name: "includeAntonyms",
            label: "Include Antonyms",
            required: true,
        },
        {
            type: "boolean",
            name: "includeEtymology",
            label: "Include Etymology",
            required: true,
        },
        {
            type: "boolean",
            name: "includePronunciation",
            label: "Include Pronunciation",
            required: true,
        },
        {
            type: "textarea",
            name: "specialInstructions",
            label: "Special Instructions",
            placeholder: "Special Instructions",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.DISCUSSION_PROMPT_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
        {
            type: "number",
            name: "questionsPerSection",
            label: "Number of Questions",
            required: true,
            min: 1,
            max: 100,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.WRITING_PROMPT_GENERATOR]: [
        {
            type: "textarea",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
        {
            type: "select",
            options: gradesMap,
            name: "gradeLevel",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "select",
            options: ESSAY_STYLE_OPTIONS,
            name: "essayStyle",
            label: "Essay Style",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "number",
            name: "wordLimit",
            label: "Word Limit",
            required: true,
            max: 1000,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.MATH_WORD_PROBLEM_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "number",
            name: "numQuestions",
            label: "Number Of Questions",
            required: true,
            max: 1000,
        },
        {
            type: "textarea",
            name: "mathTopic",
            label: "Math Standard / Objective / Topic",
            placeholder: "Math Standard / Objective / Topic",
            required: true,
        },
        {
            type: "textarea",
            name: "storyTopic",
            label: "Story Topic",
            placeholder: "Story Topic",
            required: true,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.SCIENCE_LAB_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "select",
            options: SUBJECT_OPTIONS,
            name: "subject",
            label: "Subject",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
        {
            type: "select",
            options: LAB_TYPE_OPTIONS,
            name: "labType",
            label: "Lab Type",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "number",
            name: "duration",
            label: "Lab Duration",
            required: true,
            max: 1000,
            unit: "min",
        },
        {
            type: "select",
            options: SAFETY_LEVEL_OPTIONS,
            name: "safetyLevel",
            label: "Safety Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "materials",
            label: "Materials Needed",
            placeholder: "Materials Needed",
            required: false,
        },
        {
            type: "textarea",
            name: "objectives",
            label: "Learning Objectives",
            placeholder: "Learning Objectives",
            required: false,
        },
        {
            type: "textarea",
            name: "specialInstructions",
            label: "Special Instructions",
            placeholder: "Special Instructions",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.MULTIPLE_CHOICE_QUIZ_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "subject",
            label: "Subject",
            placeholder: "Subject",
            required: true,
        },
        {
            type: "number",
            name: "numQuestions",
            label: "Number Of Questions",
            required: true,
            max: 1000,
        },
        {
            type: "textarea",
            name: "standards",
            label: "Standards",
            placeholder: "Standards",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.DEBATE_SCENARIO_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
        {
            type: "select",
            options: SCENARIO_FORMAT_OPTIONS,
            name: "format",
            label: "Debate Format",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "select",
            options: DEBATE_DIFFICULTY_OPTIONS,
            name: "difficulty",
            label: "Difficulty Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "number",
            name: "time",
            label: "Time",
            required: true,
            max: 1000,
            unit: "min",
        },
        {
            type: "number",
            name: "participants",
            label: "Number of Participants",
            required: true,
            max: 10000,
        },
        {
            type: "textarea",
            name: "specialInstructions",
            label: "Special Instructions",
            placeholder: "Special Instructions",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.ICE_BREAKER_ACTIVITIES]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "select",
            options: ICE_BREAKER_SETTINGS_OPTIONS,
            name: "settings",
            label: "Settings",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "number",
            name: "time",
            label: "Time Limit",
            required: true,
            max: 1000,
            unit: "min",
        },
        {
            type: "number",
            name: "participants",
            label: "Number of Participants",
            required: true,
            max: 10000,
        },
        {
            type: "select",
            options: [
                {
                    label: "No Material",
                    value: false,
                },
                {
                    label: "Willing to obtain material",
                    value: true,
                },
            ],
            name: "requireMaterials",
            label: "Materials Needed",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.CONTENT_SUMMARIZER]: [
        {
            type: "textarea",
            name: "originalText",
            label: "Original Text",
            placeholder: "Original Text",
            required: true,
        },
        {
            type: "select",
            options: TEXT_LENGTH_OPTIONS,
            name: "lengthOfSummary",
            label: "Length of Summary",
            required: true,
        },
    ],
    [AICompletionType.JEOPARDY_GAME_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "gradeLevel",
            label: "Grade Level",
            placeholder: "Grade Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
        {
            type: "textarea",
            name: "categories",
            label: "Categories",
            placeholder: "Categories",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.DECODABLE_TEXT_GENERATOR]: [
        {
            type: "select",
            options: DECODABLE_TEXT_PATTERN_OPTIONS,
            name: "pattern",
            label: "Pattern",
            required: true,
            layout: {
                horizontal: true,
            },
        },
    ],
    [AICompletionType.STANDARDS_UNPACKER]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade",
            placeholder: "Enter the grade level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "select",
            options: SUBJECT_OPTIONS,
            name: "subject",
            label: "Subject Area",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "standardText",
            label: "Standard Text",
            placeholder: "Standard Text",
            required: true,
        },
        // TODO: add title "More Analysis Elements"
        {
            type: "boolean",
            name: "keySkills",
            label: "Key Skills",
            required: false,
        },
        {
            type: "boolean",
            name: "prerequisites",
            label: "Prerequisites",
            required: false,
        },
        {
            type: "boolean",
            name: "essentialVocabulary",
            label: "Essential Vocabulary",
            required: false,
        },
        {
            type: "boolean",
            name: "crossCurricularConnections",
            label: "Cross-curricular Connections",
            required: false,
        },
        {
            type: "boolean",
            name: "assessmentIdeas",
            label: "Assessment Ideas",
            required: false,
        },
        {
            type: "boolean",
            name: "resourceSuggestions",
            label: "Resource Suggestions",
            required: false,
        },
        {
            type: "boolean",
            name: "differentiationStrategies",
            label: "Differentiation Strategies",
            required: false,
        },
        {
            type: "boolean",
            name: "commonMisconceptions",
            label: "Common Misconceptions",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
        {
            type: "textarea",
            name: "specialInstructions",
            label: "Special Instructions",
            placeholder: "Special Instructions",
            required: false,
        },
    ],
    [AICompletionType.JOKE_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "gradeLevel",
            label: "Grade Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
        {
            type: "textarea",
            name: "standards",
            label: "Standards",
            placeholder: "Standards",
            required: false,
        },
        {
            type: "file",
            name: "file",
            label: "Add a file for more context",
            required: false,
        },
    ],
    [AICompletionType.TEXT_TRANSLATOR]: [
        {
            type: "textarea",
            name: "originalText",
            label: "Original Text",
            placeholder: "Original Text",
            required: true,
        },
        {
            type: "textInput",
            name: "targetLanguage",
            label: "Target Language",
            placeholder: "Target Language",
            required: true,
        },
    ],
    [AICompletionType.STORY_WORD_PROBLEMS_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "gradeLevel",
            label: "Grade Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "topicObjective",
            label: "Topic / Objective",
            placeholder: "Topic / Objective",
            required: true,
        },
        {
            type: "textarea",
            name: "storyTopic",
            label: "Story Topic",
            placeholder: "Story Topic",
            required: true,
        },
        {
            type: "number",
            name: "numberOfStories",
            label: "Number of Stories",
            required: true,
            max: 100,
        },
    ],
    [AICompletionType.BIP_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "gradeLevel",
            label: "Grade Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "problemDescription",
            label: "Problem Description",
            placeholder: "Problem Description",
            required: true,
        },
    ],
    [AICompletionType.TIME_BASED_ACTIVITY_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "gradeLevel",
            label: "Grade Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "number",
            name: "duration",
            label: "Duration",
            placeholder: "Duration In Minutes",
            required: true,
        },
        {
            type: "textarea",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
    ],
    [AICompletionType.RECOMMENDATION_LETTER_GENERATOR]: [
        {
            type: "textarea",
            name: "studentFullName",
            label: "Student Full Name",
            placeholder: "Student Full Name",
            required: true,
        },
        {
            type: "textInput",
            name: "studentPronouns",
            label: "Student Pronouns",
            placeholder: "He/She/They",
            required: true,
        },
        {
            type: "textInput",
            name: "institution",
            label: "Institution",
            placeholder: "Institution",
            required: true,
        },
        {
            type: "textInput",
            name: "relationshipToStudent",
            label: "Relationship to Student",
            placeholder: "Parent, Teacher, etc.",
            required: true,
        },
        {
            type: "textarea",
            name: "keyInformation",
            label: "Key Information",
            placeholder: "Key Information",
            required: true,
        },
    ],
    [AICompletionType.PERFORMANCE_TASK_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "gradeLevel",
            label: "Grade Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textarea",
            name: "subject",
            label: "Subject",
            placeholder: "Subject",
            required: true,
        },
        {
            type: "textarea",
            name: "customInstructions",
            label: "Custom Instructions",
            placeholder: "Custom Instructions",
            required: true,
        },
        {
            type: "textarea",
            name: "standards",
            label: "Standards",
            placeholder: "Standards",
            required: false,
        },
    ],
    [AICompletionType.PLC_AGENDA_GENERATOR]: [
        {
            type: "textarea",
            name: "specificRequest",
            label: "Meeting Theme/Topic",
            placeholder: "Meeting Theme/Topic",
            required: false,
        },
        {
            type: "file",
            name: "files",
            label: "Standards/Files",
            required: false,
        },
        {
            type: "textarea",
            name: "objective",
            label: "Overall Objective / Goal",
            placeholder: "Overall Objective / Goal",
            required: false,
        },
        {
            type: "textarea",
            name: "additionalItems",
            label: "Additional Items",
            placeholder: "Additional Items",
            required: false,
        },
    ],
    [AICompletionType.SYLLABUS_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "gradeLevel",
            label: "Grade Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "textInput",
            name: "subject",
            label: "Subject",
            placeholder: "Subject",
            required: true,
        },
        {
            type: "textInput",
            name: "courseDescription",
            label: "Course Description",
            placeholder: "Course Description",
            required: true,
        },
        {
            type: "textInput",
            name: "courseObjectives",
            label: "Course Objectives",
            placeholder: "Course Objectives",
            required: true,
        },
        {
            type: "textInput",
            name: "gradingPolicy",
            label: "Grading Policy",
            placeholder: "Grading Policy",
            required: true,
        },
        {
            type: "textInput",
            name: "classroomPolicies",
            label: "Classroom Policies",
            placeholder: "Classroom Policies",
            required: true,
        },
        {
            type: "textInput",
            name: "courseOutline",
            label: "Course Outline",
            placeholder: "Course Outline",
            required: true,
        },
        {
            type: "textInput",
            name: "additionalInfo",
            label: "Additional Info",
            placeholder: "Additional Info",
            required: true,
        },
        {
            type: "textInput",
            name: "requiredMaterials",
            label: "Required Materials",
            placeholder: "Required Materials",
            required: true,
        },
        {
            type: "textInput",
            name: "standards",
            label: "Standards",
            placeholder: "Standards",
            required: true,
        },
    ],
    [AICompletionType.SONG_GENERATOR]: [
        {
            type: "textInput",
            name: "topic",
            label: "Topic",
            placeholder: "Topic",
            required: true,
        },
        {
            type: "textInput",
            name: "details",
            label: "Details",
            placeholder: "Details",
            required: true,
        },
        {
            type: "textInput",
            name: "artistName",
            label: "Artist Name",
            placeholder: "Artist Name",
            required: true,
        },
        {
            type: "textInput",
            name: "songTitle",
            label: "Song Title",
            placeholder: "Song Title",
            required: true,
        },
    ],
    [AICompletionType.MATH_SPIRAL_REVIEW_GENERATOR]: [
        {
            type: "select",
            options: gradesMap,
            name: "grade",
            label: "Grade Level",
            required: true,
            layout: {
                horizontal: true,
            },
        },
        {
            type: "number",
            name: "numProblems",
            label: "Number of Problems",
            required: true,
        },
        {
            type: "textarea",
            name: "mathContent",
            label: "Math Content",
            required: true,
        },
        {
            type: "textInput",
            name: "additionalCriteria",
            label: "Additional Criteria",
            required: false,
        },
    ],
};

export const DEFAULT_INPUTS_VALUES = {
    grade: { label: "Choose a grade", value: null },
    pointScale: { label: "3", value: "3" },
    topic: "",
    additionalInfo: "",
    standards: "",
    classroomContext: "",
    description: "",
    rubric: "",
    text: "",
    objective: "",
    time: "20",
    lengthOfWorksheet: "30",
    title: "",
    length: 0,
    difficulty: { value: "EASY", label: "Easy" },
    note: "",
    textType: { label: "Literary Nonfiction", value: "Literary Nonfiction" },
    textLength: 1,
    textLengthUnit: { label: "Paragraph", value: "Paragraph" },
    pattern: { label: "m /m/ am", value: "m /m/ am" },
    standardType: { label: "Common Core", value: "Common Core" },
    studentPronouns: "",
    strengths: "",
    areasForImprovement: "",
    teacher: "",
    studentName: "",
    author: "",
    purpose: "",
    includedContent: "",
    tone: "",
    recipient: "",
    contentCustomization: "",
    originalText: "",
    rewriteInstructions: "",
    toneOptions: PARAPPHRASE_TONE_OPTIONS[0],
    preserveKeyTerms: true,
    specialInstructions: false,
    interests: "",
    importantAnnouncements: "",
    recentCelebrations: "",
    standardsOrFiles: "",
    includeEtymology: false,
    includeAntonyms: false,
    includeSynonyms: false,
    includePartOfSpeech: false,
    numberOfWords: 50,
    subject: "",
    essayStyle: ESSAY_STYLE_OPTIONS[0],
    wordLimit: 100,
    maintainLength: false,
    includeExample: false,
    includeDefinitions: false,
    includePronunciation: false,
    gradeLevel: null,
    numQuestions: 10,
    mathTopic: "",
    storyTopic: "",
    labType: LAB_TYPE_OPTIONS[0],
    duration: "60",
    safetyLevel: SAFETY_LEVEL_OPTIONS[0],
    materials: "",
    objectives: "",
    format: SCENARIO_FORMAT_OPTIONS[0],
    participants: 5,
    settings: ICE_BREAKER_SETTINGS_OPTIONS[0],
    contexts: "",
    lengthOfSummary: "short",
    questionsPerSection: 5,
};
