import { TEACHERS_PRICING_OPTIONS } from "@knowt/syncing/hooks/user/subscriptionConstants";
import { ASSETS_URL } from "@/config/deployConstants";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { themeColors } from "@/utils/themeColors";
import { type PricingPropsUI, getBulkDiscountPrice } from "./constants";
import { SubscriptionType } from "@knowt/syncing/graphql/schema";

const kaiHeadImg = `${ASSETS_URL}/images/kai-head.svg`;
const crownImg = "/images/crown.svg";
const magicStarsImg = `${ASSETS_URL}/images/magic-ai-icon.svg`;

export const TEACHERS_MONTHLY_BASIC: PricingPropsUI = {
    ...TEACHERS_PRICING_OPTIONS.MONTHLY_BASIC,
    description: "",
    features: [
        "Create 1 class with unlimited sections",
        "Create, and share, unlimited notes and flashcards with students",
        "Free Learn Mode for your students",
    ],
    onClick: () => Mixpanel.track("Teacher Free Plan Monthly - Clicked", { price: 0 }),
    icon: kaiHeadImg,
    buttonBackground: themeColors.neutralBlack,
    benefits: [
        "Create 1 class with unlimited sections",
        "Create, and share, unlimited notes and flashcards with students",
        "Free Learn Mode for your students",
    ],
};

export const TEACHERS_MONTHLY_PRO: PricingPropsUI = {
    ...TEACHERS_PRICING_OPTIONS.MONTHLY_PRO,
    description: `Everything on "Teacher Basic" and:`,
    features: [
        "Create unlimited classes with unlimited sections",
        "Tracking Class Progress",
        "No ads",
        "Folder color, profile page customization, & password protecting files",
    ],
    onClick: () => Mixpanel.track("Teacher Pro Plan - Clicked", { price: 5.99 }),
    icon: crownImg,
    benefits: [
        "Create unlimited classes with unlimited sections",
        "Tracking Class Progress",
        "No ads",
        "Folder color, profile page customization, & password protecting files",
    ],
};

export const TEACHERS_ANNUALLY_PRO: PricingPropsUI = {
    ...TEACHERS_MONTHLY_PRO,
    ...TEACHERS_PRICING_OPTIONS.ANNUALLY_PRO,
    onClick: () => Mixpanel.track("Teacher Pro Plan - Clicked", { price: 35.99 }),
};

export const TEACHERS_MONTHLY_LIMITLESS: PricingPropsUI = {
    ...TEACHERS_PRICING_OPTIONS.MONTHLY_LIMITLESS,
    description: `Everything on "Teacher Plus" and:`,
    features: [
        "No ads for students in your classes",
        "Unlimited access to other AI features*",
        "Create notes and flashcards from your lecture videos and notes",
    ],
    onClick: () => Mixpanel.track("Teacher Limitless Plan Monthly - Clicked", { price: 12.99 }),
    icon: magicStarsImg,
    buttonBackground: "#50D2C2",
    background: themeColors.neutralBlack,
    benefits: [
        "No ads for students in your classes",
        "Unlimited access to other AI features",
        "Create notes and flashcards from your lecture videos and notes",
    ],
    mostPopular: true,
};

export const TEACHERS_ANNUALLY_LIMITLESS: PricingPropsUI = {
    ...TEACHERS_MONTHLY_LIMITLESS,
    ...TEACHERS_PRICING_OPTIONS.ANNUALLY_LIMITLESS,
    onClick: () => Mixpanel.track("Teacher Limitless Plan Annually - Clicked", { price: 95.99 }),
};

export const TEACHERS_MONTHLY_BASIC_PICKER: PricingPropsUI = {
    ...TEACHERS_MONTHLY_BASIC,
    features: [
        "Create 1 class with unlimited sections",
        "Create, and share, unlimited notes and flashcards with students",
        "Free Learn Mode for your students",
        "Students can not share files with anyone",
    ],
    isPicker: true,
    buttonBackground: themeColors.neutralBlack,
    intervalText: "per teacher anually",
};

export const TEACHERS_ANNUALLY_PLUS_PICKER = (nbrLicense: number) => ({
    ...TEACHERS_MONTHLY_BASIC_PICKER,
    ...TEACHERS_ANNUALLY_PRO,
    price: getBulkDiscountPrice(nbrLicense, TEACHERS_ANNUALLY_PRO.price),
    oldPrice: TEACHERS_ANNUALLY_PRO.price,
    buttonBackground: themeColors.primary,
    background: themeColors.neutralWhite,
    intervalText: "per teacher annually",
});

export const TEACHERS_ANNUALLY_LIMITLESS_PICKER = (nbrLicense: number) => ({
    ...TEACHERS_ANNUALLY_LIMITLESS,
    features: [
        "Unlimited access to other AI features*",
        "Create notes and flashcards from your lecture videos and notes",
        "No ads for students in your classes",
    ],
    isPicker: true,
    price: getBulkDiscountPrice(nbrLicense, TEACHERS_ANNUALLY_LIMITLESS.price),
    oldPrice: TEACHERS_ANNUALLY_LIMITLESS.price,
    buttonBackground: themeColors.primary,
    background: themeColors.neutralBlack,
    intervalText: "per teacher annually",
});

export const ENTREPRISE_CONTACT_US: PricingPropsUI = {
    type: "Enterprise",
    subscriptionType: SubscriptionType.LIMITLESS,
    interval: "Year",
    price: "Contact Us",
    // TODO: update priceId
    //priceId: PRICE_ID.LIMITLESS_YEARLY_120,
    description: "Everything on Knowt Ultra and:",
    features: [
        "SSO (Single Sign On)",
        "Google Classroom, Canvas, or other LMS Integration",
        "24/7 Support",
        "One tap sign in shortcuts on Classlink or Clever",
    ],
    background: themeColors.primaryLight,
    buttonBackground: themeColors.primary,
    needContactUs: true,
    icon: `${ASSETS_URL}/images/stationary.svg`,
    onClick: () => window.open("https://calendly.com/knowt-abheek/knowt-bulk-pricing"),
};
