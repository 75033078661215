import { iconSizes } from "@/utils/iconProps";
import { themeColors } from "@/utils/themeColors";

const SparkleIcon = ({
    color = themeColors.ai,
    size = iconSizes.SM_S,
    ...props
}: React.SVGProps<SVGSVGElement> & { size?: number; color?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill={color} {...props}>
            <path
                d="M8 2L6.73333 5.86667C6.66847 6.06748 6.55688 6.25003 6.40772 6.3993C6.25856 6.54858 6.07609 6.66032 5.87533 6.72533L2 8L5.86667 9.26667C6.06748 9.33153 6.25003 9.44312 6.3993 9.59228C6.54858 9.74144 6.66032 9.92391 6.72533 10.1247L8 14L9.26667 10.1333C9.33153 9.93252 9.44312 9.74997 9.59228 9.6007C9.74144 9.45142 9.92391 9.33968 10.1247 9.27467L14 8L10.1333 6.73333C9.93252 6.66847 9.74997 6.55688 9.6007 6.40772C9.45142 6.25856 9.33968 6.07609 9.27467 5.87533L8 2Z"
                fill={color}
                stroke={color}
                strokeWidth="1.33333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
};

export default SparkleIcon;
