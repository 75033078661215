import SparkleIcon from "@/components/icons/SparkleIcon";
import { ASSETS_URL } from "@/config/deployConstants";
import Mixpanel from "@/utils/analytics/Mixpanel";
import { themeColors } from "@/utils/themeColors";
import { STUDENTS_PRICING_OPTIONS } from "@knowt/syncing/hooks/user/subscriptionConstants";
import { type PricingPropsUI, getBulkDiscountPrice } from "./constants";

const crownImg = "/images/crown.svg";
const kaiHeadImg = `${ASSETS_URL}/images/kai-head.svg`;
const diamondImg = `${ASSETS_URL}/images/ultra.svg`;

const percentageIcon = (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 23 23" fill="none">
        <path
            d="M20.5674 9.60068L19.5664 8.60151C19.3216 8.35467 19.1836 8.02149 19.1823 7.67384V6.50234C19.1813 5.79015 18.8979 5.10743 18.3942 4.60392C17.8906 4.10041 17.2077 3.81724 16.4955 3.81651H15.3259C15.1536 3.81696 14.983 3.78334 14.8238 3.71758C14.6646 3.65182 14.5199 3.55522 14.3982 3.43334L13.399 2.43326C12.8949 1.93036 12.2118 1.64795 11.4997 1.64795C10.7876 1.64795 10.1046 1.93036 9.60037 2.43326L8.6012 3.43334C8.47955 3.55539 8.33496 3.65215 8.17575 3.71807C8.01654 3.78399 7.84586 3.81776 7.67354 3.81742H6.50295C5.79077 3.81815 5.10794 4.10133 4.60426 4.60484C4.10058 5.10834 3.81717 5.79107 3.8162 6.50326V7.67384C3.81527 8.02112 3.67762 8.35405 3.43304 8.60059L2.43204 9.60068C1.92914 10.1049 1.64673 10.7879 1.64673 11.5C1.64673 12.2121 1.92914 12.8952 2.43204 13.3993L3.43304 14.3985C3.67784 14.6453 3.81579 14.9785 3.81712 15.3262V16.4977C3.81809 17.2099 4.1015 17.8926 4.60518 18.3961C5.10886 18.8996 5.79168 19.1828 6.50387 19.1835H7.67354C7.84586 19.1832 8.01654 19.2169 8.17575 19.2829C8.33496 19.3488 8.47955 19.4455 8.6012 19.5676L9.60037 20.5677C10.1044 21.0708 10.7875 21.3534 11.4997 21.3534C12.2119 21.3534 12.895 21.0708 13.399 20.5677L14.3982 19.5676C14.5199 19.4455 14.6645 19.3488 14.8237 19.2829C14.9829 19.2169 15.1536 19.1832 15.3259 19.1835H16.4965C17.2086 19.1828 17.8915 18.8996 18.3951 18.3961C18.8988 17.8926 19.1822 17.2099 19.1832 16.4977V15.3262C19.184 14.9787 19.322 14.6456 19.5673 14.3994L20.5674 13.3993C21.0703 12.8952 21.3527 12.2121 21.3527 11.5C21.3527 10.7879 21.0703 10.1049 20.5674 9.60068Z"
            fill="#FFC545"
        />
        <path
            d="M13.7935 15.167C14.5528 15.167 15.1685 14.5514 15.1685 13.792C15.1685 13.0326 14.5528 12.417 13.7935 12.417C13.0341 12.417 12.4185 13.0326 12.4185 13.792C12.4185 14.5514 13.0341 15.167 13.7935 15.167Z"
            fill="white"
        />
        <path
            d="M9.20923 10.5835C9.96862 10.5835 10.5842 9.96789 10.5842 9.2085C10.5842 8.4491 9.96862 7.8335 9.20923 7.8335C8.44984 7.8335 7.83423 8.4491 7.83423 9.2085C7.83423 9.96789 8.44984 10.5835 9.20923 10.5835Z"
            fill="white"
        />
        <path
            d="M8.91015 14.781C8.77427 14.7806 8.64154 14.74 8.52868 14.6643C8.41582 14.5887 8.32788 14.4813 8.27593 14.3557C8.22398 14.2302 8.21035 14.0921 8.23676 13.9588C8.26316 13.8255 8.32842 13.703 8.42432 13.6067L13.6108 8.42113C13.7421 8.30576 13.9123 8.2447 14.087 8.25036C14.2616 8.25602 14.4276 8.32797 14.5511 8.45159C14.6746 8.57522 14.7464 8.74123 14.7519 8.91589C14.7574 9.09054 14.6961 9.26074 14.5807 9.39188L9.39415 14.5793C9.26591 14.708 9.09183 14.7805 8.91015 14.781Z"
            fill="white"
        />
    </svg>
);

export const STUDENTS_MONTHLY_BASIC: PricingPropsUI = {
    ...STUDENTS_PRICING_OPTIONS.MONTHLY_BASIC,
    description: "",
    features: ["Unlimited Learn Mode", "Unlimited Videos & PDFs"],
    onClick: () => Mixpanel.track("Basic Plan - Clicked", { price: 0 }),
    icon: kaiHeadImg,
};

export const STUDENTS_MONTHLY_SUPPORTER: PricingPropsUI = {
    ...STUDENTS_PRICING_OPTIONS.MONTHLY_SUPPORTER,
    description: 'Everything on "Student Basic" and',
    features: [
        "No Ads",
        "Folder color, profile page customization & password protecting files",
        "Unlimited explanations on incorrect answers",
    ],
    onClick: () => Mixpanel.track("Ad Free Plan Monthly - Clicked", { price: 5.99 }),
    icon: crownImg,
    benefits: [
        "No Ads",
        "Folder color & profile page customization",
        "Unlimited explanations on incorrect answers",
        "Password protect files",
        "Public profile badge",
        "100 AI generated flashcard definitions per month",
        "100 AI note prompts per month",
        "30 AI generated flashcard sets from notes per month",
        "30 AI generated tests from notes per month ",
    ],
};

export const STUDENTS_ANNUALLY_SUPPORTER: PricingPropsUI = {
    ...STUDENTS_MONTHLY_SUPPORTER,
    ...STUDENTS_PRICING_OPTIONS.ANNUALLY_SUPPORTER,
    onClick: () => Mixpanel.track("Ad Free Plan Annually - Clicked", { price: 23.99 }),
};

export const STUDENTS_MONTHLY_LIMITLESS: PricingPropsUI = {
    ...STUDENTS_PRICING_OPTIONS.MONTHLY_LIMITLESS,
    description: 'Everything on "Student Plus" and:',
    features: [
        "Unlimited flashcards made from your notes using AI",
        "Unlimited notes & flashcards made from your lecture videos with AI",
        "Unlimited practice tests made from your lecture and PDFs with AI",
        "Unlimited explanations on incorrect answers",
    ],
    onClick: () => Mixpanel.track("Limitless Plan Monthly - Clicked", { price: 12.99 }),
    icon: diamondImg,
    benefits: [
        "No Ads",
        "Folder color & profile page customization",
        "Password protect files",
        "Public profile badge",
        "Unlimited explanations on incorrect answers",
        "Unlimited AI generated flashcard definitions per month",
        "Unlimited AI note prompts per month",
        "Unlimited AI generated flashcard sets from notes per month",
        "Unlimited AI generated tests from notes per month",
        "Unlimited AI generated notes from videos per month",
        "Unlimited AI generated flashcards from videos per month",
        "Unlimited chat messages with videos per month",
        "Unlimited AI generated notes from PDFs per month",
        "Unlimited AI generated flashcards from PDFs per month",
        "Unlimited chat messages with PDFs per month",
    ],
    mostPopular: true,
    buttonBackground: "#50D2C2",
    background: themeColors.neutralBlack,
};

export const STUDENTS_ANNUALLY_LIMITLESS: PricingPropsUI = {
    ...STUDENTS_MONTHLY_LIMITLESS,
    ...STUDENTS_PRICING_OPTIONS.ANNUALLY_LIMITLESS,
    onClick: () => Mixpanel.track("Limitless Plan Annually - Clicked", { price: 47.99 }),
};

export const STUDENT_MONTHLY_BASIC_PICKER: PricingPropsUI = {
    ...STUDENTS_MONTHLY_BASIC,
    type: "Student Basic",
    features: [
        "No ads",
        "Student’s can’t share files with anyone",
        "Free Learn Mode",
        "Create unlimited notes, and flashcards",
    ],
    isPicker: true,
    buttonBackground: themeColors.neutralBlack,
    background: themeColors.neutralWhite,
    intervalText: "per student annually",
};

export const STUDENT_ANNUALLY_PLUS_PICKER = (nbrLicense: number): PricingPropsUI => ({
    ...STUDENTS_ANNUALLY_SUPPORTER,
    type: "Student Plus",
    price: getBulkDiscountPrice(nbrLicense, "$6.00"),
    oldPrice: "$6.00",
    features: [
        "100% ad-free",
        "Enable students to share files only inside the school",
        "Personalization - Folder color, App Icon",
        "Unlimited AI explanations on incorrect answers during Learn mode",
    ],
    isPicker: true,
    buttonBackground: themeColors.primary,
    background: themeColors.neutralWhite,
    intervalText: "per student annually",
    specialBenefit: {
        benefit: "Students can use unlimited AI to study outside class",
        icon: percentageIcon,
        style: {
            backgroundColor: themeColors.flashcardsLight,
        },
    },
});

export const STUDENT_ANNUALLY_LIMITLESS_PICKER = (nbrLicense: number): PricingPropsUI => ({
    ...STUDENTS_ANNUALLY_LIMITLESS,
    type: "Student Ultra",
    price: getBulkDiscountPrice(nbrLicense, STUDENTS_ANNUALLY_LIMITLESS.price),
    oldPrice: STUDENTS_ANNUALLY_LIMITLESS.price,
    features: [
        "Unlimited access to other AI features*",
        "Create notes and flashcards from your lecture videos and notes",
        "Unlimited answer explanations during learn mode",
    ],
    isPicker: true,
    buttonBackground: themeColors.primary,
    background: themeColors.neutralBlack,
    intervalText: "per student annually",
});

export const STUDENT_ANNUALLY_LIMITLESS_BULK_50 = (): PricingPropsUI => ({
    ...STUDENT_ANNUALLY_LIMITLESS_PICKER(50),
    upTo: 50,
    isPicker: true,
    perfectFor: "Perfect for just teachers",
    features: [
        "No Ads for you or your students",
        "Unlimited practice tests with AI on files in your classes",
        "Track Student Progress",
        "Unlimited AI Chats with Kai on files in your classes",
        "Impose study mode restrictions",
    ],
    intervalText: "per user/year",
    specialBenefit: {
        benefit: "Students have unlimited AI access on files in a class",
        icon: percentageIcon,
        style: {
            backgroundColor: themeColors.flashcardsLight,
        },
    },
});

export const STUDENT_ANNUALLY_LIMITLESS_BULK_150 = (): PricingPropsUI => ({
    ...STUDENT_ANNUALLY_LIMITLESS_BULK_50(),
    upTo: 150,
    isPicker: true,
    price: getBulkDiscountPrice(150, STUDENTS_ANNUALLY_LIMITLESS.price),
    perfectFor: "Perfect for smaller schools",
    minimumPurchase: "3,500",
});

export const STUDENT_ANNUALLY_LIMITLESS_BULK_500 = (): PricingPropsUI => ({
    ...STUDENT_ANNUALLY_LIMITLESS_BULK_50(),
    upTo: 500,
    price: getBulkDiscountPrice(500, STUDENTS_ANNUALLY_LIMITLESS.price),
    perfectFor: "Perfect for medium-sized schools",
    minimumPurchase: "6,000",
});

export const STUDENT_ANNUALLY_LIMITLESS_BULK_1000 = (): PricingPropsUI => ({
    ...STUDENT_ANNUALLY_LIMITLESS_BULK_50(),
    upTo: 1000,
    price: getBulkDiscountPrice(1000, STUDENTS_ANNUALLY_LIMITLESS.price),
    perfectFor: "Perfect for larger schools",
    minimumPurchase: "10,000",
    specialBenefit: {
        benefit: "Students can use unlimited AI to study outside class",
        icon: <SparkleIcon size={40} />,
        style: {
            backgroundImage: themeColors.aiGradientFlip,
            color: themeColors.pureBlack,
        },
    },
});

export const STUDENT_ANNUALLY_LIMITLESS_BULK_2000 = (): PricingPropsUI => ({
    ...STUDENT_ANNUALLY_LIMITLESS_BULK_50(),
    upTo: 2000,
    price: getBulkDiscountPrice(2000, STUDENTS_ANNUALLY_LIMITLESS.price),
    perfectFor: "As low as $0.50 per user/month",
    minimumPurchase: "14,000",
    intervalText: undefined,
    specialBenefit: {
        benefit: "Students can use unlimited AI to study outside class",
        icon: <SparkleIcon size={40} />,
        style: {
            backgroundImage: themeColors.aiGradientFlip,
            color: themeColors.pureBlack,
        },
    },
    isCustom: true,
});
