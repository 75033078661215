import {
    AccountType,
    DPAType,
    Organization,
    SignInType,
    SubscriptionType,
    UserDetails,
} from "@knowt/syncing/graphql/schema";
import GUIDED_EVENTS, { AllEventNames } from "@knowt/syncing/hooks/guidedEvents/guidedEvents";
import { isGuidedEventDone } from "@knowt/syncing/hooks/guidedEvents/utils";
import { now } from "@/utils/dateTimeUtils";
import { TIME_SECONDS } from "@/utils/dateTimeUtils";
import { getUserPlan, isOrgAdmin, isSomeBasicInfoMissing } from "@knowt/syncing/hooks/user/graphqlUtils";
import { canSubscribe } from "../user/subscriptions";

export enum INITIAL_SETUP_STEPS {
    BASIC_INFO = "BASIC_INFO",
    REFERRAL = "REFERRAL",
    SCHOOL_SELECTION = "SCHOOL_SELECTION",
    LINK_SSO_ACCOUNT = "LINK_SSO_ACCOUNT",
    LINK_EMAIL_ACCOUNT = "LINK_EMAIL_ACCOUNT",
    ORG_MANAGED_ACCOUNT = "ORG_MANAGED_ACCOUNT",
    ORG_UPGRADED_ACCOUNT = "ORG_UPGRADED_ACCOUNT",
    PLAN_PROMOTION = "PLAN_PROMOTION",
}

export type InitialSetupFlowStep = {
    key: INITIAL_SETUP_STEPS;
    condition: () => boolean;
};

export const getInitialSetupSteps = ({
    user,
    organization,
}: {
    user: UserDetails | null | undefined;
    organization: Organization | null | undefined;
}): InitialSetupFlowStep[] => {
    if (!user) {
        return [];
    }

    const orgAdmin = isOrgAdmin({ user, organization });

    // Case 1: Normal account, org paid for a plan and SSO
    const shouldLinkSSOAccount =
        (user.orgPlanType ?? SubscriptionType.BASIC) !== SubscriptionType.BASIC &&
        user.signInType !== SignInType.EDLINK &&
        organization?.provider;

    // Case 2: Normal account, org paid for a plan but no SSO
    const shouldShowOrgUpgradedAccount =
        !user.elID && !shouldLinkSSOAccount && (user.orgPlanType ?? SubscriptionType.BASIC) !== SubscriptionType.BASIC;

    // Case 3: Normal account, no plan, org just signed a DPA
    const shouldShowOrgManaged =
        !user.elID &&
        !shouldLinkSSOAccount &&
        !shouldShowOrgUpgradedAccount &&
        organization?.dpa &&
        organization?.dpa.type !== DPAType.NONE &&
        organization?.verifiedSchools;

    // EdLink Account, ask the user to link their normal account
    const shouldLinkEmailAccount =
        user.signInType === SignInType.EDLINK &&
        // if the user has only one sign in type, they should link their email, google, apple, or facebook account
        user.signInTypes.length === 1;

    const areSubsAllowed = canSubscribe({ organization, user });

    const steps = {
        [INITIAL_SETUP_STEPS.BASIC_INFO]: {
            key: INITIAL_SETUP_STEPS.BASIC_INFO,
            condition: () =>
                // if birthday, account type, or username is missing
                isSomeBasicInfoMissing(user),
        },
        [INITIAL_SETUP_STEPS.REFERRAL]: {
            key: INITIAL_SETUP_STEPS.REFERRAL,
            condition: () =>
                // if the referral isnt set ("" counts)
                !orgAdmin && (user.referral === null || user.referral === undefined),
        },
        [INITIAL_SETUP_STEPS.SCHOOL_SELECTION]: {
            key: INITIAL_SETUP_STEPS.SCHOOL_SELECTION,
            condition: () =>
                // only for non org admins, and if the schools for the district have been verified by the admin
                !orgAdmin && !!organization?.verifiedSchools && !user.schoolId,
        },
        [INITIAL_SETUP_STEPS.LINK_SSO_ACCOUNT]: {
            key: INITIAL_SETUP_STEPS.LINK_SSO_ACCOUNT,
            condition: () =>
                !orgAdmin &&
                shouldLinkSSOAccount &&
                // on completion of this step, elID should be set
                !user.elID,
        },
        [INITIAL_SETUP_STEPS.LINK_EMAIL_ACCOUNT]: {
            key: INITIAL_SETUP_STEPS.LINK_EMAIL_ACCOUNT,
            condition: () =>
                !orgAdmin &&
                shouldLinkEmailAccount &&
                // on completion of this step, this event should be marked as done
                !isGuidedEventDone({
                    user,
                    organization,
                    event: GUIDED_EVENTS.INITIAL_SETUP.find(
                        event => event.eventName === AllEventNames.LINK_EMAIL_ACCOUNT
                    ),
                }),
        },
        [INITIAL_SETUP_STEPS.ORG_MANAGED_ACCOUNT]: {
            key: INITIAL_SETUP_STEPS.ORG_MANAGED_ACCOUNT,
            condition: () =>
                !orgAdmin &&
                shouldShowOrgManaged &&
                // on completion of this step, this event should be marked as done
                !isGuidedEventDone({
                    user,
                    organization,
                    event: GUIDED_EVENTS.INITIAL_SETUP.find(
                        event => event.eventName === AllEventNames.ORG_MANAGED_ACCOUNT
                    ),
                }),
        },
        [INITIAL_SETUP_STEPS.ORG_UPGRADED_ACCOUNT]: {
            key: INITIAL_SETUP_STEPS.ORG_UPGRADED_ACCOUNT,
            condition: () =>
                !orgAdmin &&
                shouldShowOrgUpgradedAccount &&
                // on completion of this step, this event should be marked as done
                !isGuidedEventDone({
                    user,
                    organization,
                    event: GUIDED_EVENTS.INITIAL_SETUP.find(
                        event => event.eventName === AllEventNames.ORG_UPGRADED_ACCOUNT
                    ),
                }),
        },

        [INITIAL_SETUP_STEPS.PLAN_PROMOTION]: {
            key: INITIAL_SETUP_STEPS.PLAN_PROMOTION,
            condition: () =>
                user.accountType === AccountType.Student &&
                !orgAdmin &&
                areSubsAllowed &&
                user.created > now() - 10 * TIME_SECONDS.MINUTE &&
                getUserPlan(user) === SubscriptionType.BASIC &&
                !isGuidedEventDone({
                    user,
                    organization,
                    event: GUIDED_EVENTS.PLAN_PROMOTION.find(
                        event => event.eventName === AllEventNames.STUDENT_TRIAL_2024
                    ),
                }),
        },
    };

    return Object.values(steps).filter(step => step.condition());
};
