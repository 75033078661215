import { UserDetails } from "@/graphql/schema";
import { PlatformType } from "@/platform";
import { SingleKey } from "@/types/common";

export type GuidedEvent = GuidedEventObject[];

export type GuidedEvents = {
    [key in keyof GuidedEventsKeys]: GuidedEvent;
};

export enum GuidedEventUserPropType {
    LESS = 0,
    MORE = 1,
    EQUAL = 2,
}

export enum GuidedEventAction {
    ADD = 0,
    REMOVE = 1,
}

export type GuidedEventObject = {
    eventName: AllEventNames;
    visitCount?: number;
    userProp?: SingleKey<UserDetails> & {
        type: GuidedEventUserPropType;
    };
    action?: GuidedEventAction;
    platform?: PlatformType; // if not given, it will be shown on all platforms
};

export type GuidedEventsKeys = {
    ON_HOME_PAGE: string;
    CLASS_DASHBOARD: string;
    PLAN_PROMOTION: string;
    PRACTICE_ROOM_AI_DISCLAIMER: string;
    INITIAL_SETUP: string;
    GAMIFICATION: string;
    ORG_INTIAL_SETUP: string;
};

export enum AllEventNames {
    EXPERIENCE = "experience",
    REFERRAL_CODE = "referral-code",
    TEACHERS_TRIAL_2024 = "teachers-trial-2024",
    STUDENT_TRIAL_2024 = "student-trial-2024",
    PRIVACY_AGREEMENT_MARCH_2024 = "privacy-agreement-march-2024",
    PRACTICE_ROOM_AI_DISCLAIMER = "practice-room-ai-disclaimer",
    LINK_SSO_ACCOUNT = "link-sso-account",
    LINK_EMAIL_ACCOUNT = "link-email-account",
    ORG_UPGRADED_ACCOUNT = "org-upgraded-account",
    ORG_MANAGED_ACCOUNT = "org-managed-account",
    ORG_ADMIN_SSO = "org-admin-sso",
    FIRST_STUDY_TIME = "first-study-time",
    GAMIFICATION_INTRO = "gamification-intro",
    FIRST_TIME_STORE = "first-time-store",
}

const GUIDED_EVENTS: GuidedEvents = {
    ON_HOME_PAGE: [
        {
            eventName: AllEventNames.EXPERIENCE,
            visitCount: 1,
            platform: PlatformType.WEB,
        },

        {
            eventName: AllEventNames.PRIVACY_AGREEMENT_MARCH_2024, //! IMPORTANT: don't remove this
            userProp: {
                created: 1711692769, // backend expects in seconds (not ms)
                type: GuidedEventUserPropType.LESS, // only show this event to users created before March 28, 2024
            },
        },
    ],
    GAMIFICATION: [
        { eventName: AllEventNames.GAMIFICATION_INTRO, visitCount: 1 },
        { eventName: AllEventNames.FIRST_STUDY_TIME },
        { eventName: AllEventNames.FIRST_TIME_STORE },
    ],
    CLASS_DASHBOARD: [{ eventName: AllEventNames.TEACHERS_TRIAL_2024 }],
    PLAN_PROMOTION: [
        {
            eventName: AllEventNames.STUDENT_TRIAL_2024,
            userProp: {
                created: 1733515219,
                type: GuidedEventUserPropType.MORE,
            },
        },
    ],
    PRACTICE_ROOM_AI_DISCLAIMER: [{ eventName: AllEventNames.PRACTICE_ROOM_AI_DISCLAIMER }],
    INITIAL_SETUP: [
        { eventName: AllEventNames.REFERRAL_CODE },
        {
            eventName: AllEventNames.LINK_SSO_ACCOUNT,
        },
        {
            eventName: AllEventNames.LINK_EMAIL_ACCOUNT,
        },
        {
            eventName: AllEventNames.ORG_MANAGED_ACCOUNT,
        },
        {
            eventName: AllEventNames.ORG_UPGRADED_ACCOUNT,
        },
    ],
    ORG_INTIAL_SETUP: [
        {
            eventName: AllEventNames.ORG_ADMIN_SSO,
        },
    ],
};

export const allEventNamesList = Object.values(GUIDED_EVENTS)
    // biome-ignore lint/performance/noAccumulatingSpread: <explanation>
    .reduce((acc, curr) => [...acc, ...curr], [])
    .map(event => event.eventName);

export default GUIDED_EVENTS;
