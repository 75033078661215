import LocalKeyValueStore from "@/utils/LocalKeyValueStore";
import { STORAGE_KEYS } from "@knowt/syncing/constants";
import { Organization, UserDetails } from "@knowt/syncing/graphql/schema";
import { getInitialSetupSteps } from "@knowt/syncing/hooks/guidedEvents/initialSetupConstants";

export const getInitialSetupUrl = async ({
    user,
    organization,
}: {
    user: UserDetails | undefined | null;
    organization?: Organization | undefined | null;
}) => {
    if (!user) return null;

    if (typeof localStorage !== "undefined") {
        const migKey = (await LocalKeyValueStore.getWithExpiry("migKey")) as string;
        const oldAccountUserId = (await LocalKeyValueStore.getWithExpiry("userId")) as string;

        if (migKey && oldAccountUserId && oldAccountUserId !== user.ID) {
            if (window.location.pathname !== "/migrate-account") {
                return `/migrate-account`;
            }

            return null;
        }

        const ssoOldAccountUserId = (await LocalKeyValueStore.getWithExpiry("ssoUserId")) as string;

        if (migKey && ssoOldAccountUserId && ssoOldAccountUserId !== user.ID) {
            if (window.location.pathname !== "/sso/login") {
                return `/sso/login?code=pass`;
            }

            return null;
        }
    }

    const shouldSkipIntroPopups =
        typeof localStorage !== "undefined" &&
        (await LocalKeyValueStore.getWithExpiry(STORAGE_KEYS.SKIP_INTRO_POPUPS)) === "true";

    if (shouldSkipIntroPopups) {
        return null;
    }

    const initialSetupSteps = getInitialSetupSteps({ user, organization });

    if (initialSetupSteps.length === 0) {
        return null;
    }

    return "/initial-setup";
};
